import { Grid, styled } from '@streem/ui-react';
import { tabWrapperSharedProps } from './shared_styled_components';
import { NotesAndTranscriptions } from './summary/notes_and_transcriptions';
import { VideoAndBookmarks } from './summary/video_and_bookmarks';

interface CallDetailSummaryTabProps {
    readOnly?: boolean;
    hiddenYn: boolean;
}
export const CallDetailSummaryTab = ({
    readOnly,
    hiddenYn,
}: CallDetailSummaryTabProps): JSX.Element => {
    return (
        <SummaryTabWrapper data-testid="call-detail-summary-tab" hiddenYn={hiddenYn}>
            <VideoAndBookmarks readOnly={readOnly} />
            <NotesAndTranscriptions readOnly={readOnly} />
        </SummaryTabWrapper>
    );
};

const SummaryTabWrapper = styled(Grid)<{ hiddenYn: boolean }>(({ hiddenYn }) => ({
    columnGap: '1.5rem',
    gridTemplateColumns: '3fr 2fr',
    ...tabWrapperSharedProps({ hiddenYn }),
}));
