import {
    AppText,
    Box,
    Button,
    Card,
    Column,
    Header,
    MeshBackground,
    Row,
    StreemStackedLogoLegal,
} from '@streem/ui-react';
import { centralLoginUrl } from '../../util/routing';

const RedirectToWorkspacesPage = ({ onLogOut }: { onLogOut?: () => void }) => {
    return (
        <MeshBackground>
            <Row width="90%" margin="auto" minWidth="1100px">
                <Column
                    data-testid="redirect-to-workspaces-page"
                    alignItems="center"
                    justifyContent="center"
                    height="100vh"
                    width="100%"
                >
                    <Card style={{ marginBottom: '32px' }}>
                        <Box mb={5} style={{ textAlign: 'center' }}>
                            <Row justifyContent="center" mb={4}>
                                <Header size="xlarge">Page not found</Header>
                            </Row>
                            <AppText size="mediumLarge">
                                Unfortunately the page you're looking for does not exist. <br />
                                Try going back to the homepage.
                            </AppText>
                        </Box>
                        <Row justifyContent="center">
                            {onLogOut && (
                                <Button
                                    data-testid="on-log-out"
                                    marginRight="16px"
                                    variant="secondary"
                                    onClick={onLogOut}
                                >
                                    Log out
                                </Button>
                            )}
                            <Button
                                data-testid="central-login-button"
                                onClick={() => {
                                    window.location.assign(centralLoginUrl());
                                }}
                            >
                                Go back home
                            </Button>
                        </Row>
                    </Card>
                    <Box position="absolute" bottom="100px">
                        <StreemStackedLogoLegal color="white" width="240px" height="180px" />
                    </Box>
                </Column>
            </Row>
        </MeshBackground>
    );
};

export default RedirectToWorkspacesPage;
