import { recordArtifactViewed } from '@streem/analytics';
import { useMediaBreakpoint, useObservable } from '@streem/sdk-react';
import {
    AppIcon,
    AppText,
    Box,
    Button,
    Card,
    IconButton,
    Row,
    Skeleton,
    styled,
    Subheader,
    useTheme,
} from '@streem/ui-react';
import { useObserver } from 'mobx-react';
import { CSSProperties, FC, useEffect, useRef, useState } from 'react';
import { streem, WallItem } from 'streem-sdk-protobuf';
import { useDetailSession } from '../../hooks/detail_session_hooks';
import { useGlobalStore } from '../../hooks/use_global_context';
import { isRecordingReady, isPreviewReady, isRecordingPending } from '../../util/artifacts';
import { isChrome } from '../../util/is_chrome';
import appLogger from '../../util/logging/app_logger';
import { RecordingThumbnail, RecordingTrackThumbnail, StreemshotThumbnail } from './thumbnail';
import { TimeStampController } from '../../util/timestamp_controller';
import {
    TimeStampControllerProvider,
    useTimeStampController,
} from '../../hooks/use_timestamp_controller';
import { MediaGalleryBookmark } from './bookmark';
import { useRawTranscription } from '../../hooks/use_raw_transcription';
import { CallTranscription } from './call_transcription';
import { MediaGallerySelected } from './media_gallery_selected';
import { StreemshotImageData } from '../../util/media_gallery_selected_utils';

const log = appLogger.extend('Media Gallery');

/**
 * Responsive mosaic grid of recordings and streemshots
 */
export const MediaGallery: FC<{
    readOnly: boolean;
    videoPreviewEnabled?: boolean;
}> = ({ readOnly, videoPreviewEnabled }) => {
    const detailSession = useDetailSession();

    const { authStore, companySettingsStore } = useGlobalStore();
    const { isMobile } = useMediaBreakpoint();
    const { transcriptionsEnabled } = companySettingsStore;

    const [streemshots = []] = useObservable(detailSession.streemshots.responses);
    const [recordings = []] = useObservable(detailSession.recording.recordings);
    const [bookmarks = []] = useObservable(detailSession.bookmarks.bookmarks);
    const [callStartTime] = useObservable(detailSession.bookmarks.callStartTime);
    const [transcriptions = []] = useObservable(detailSession.transcription.transcriptions);

    const transcriptionArtifact =
        transcriptionsEnabled && transcriptions.length > 0 ? transcriptions[0] : null;

    const [[selectedStreemshotIds], setSelectedStreemshotIds] = useState<[Set<string>]>([
        new Set(),
    ]);

    const [errorMessage, setErrorMessage] = useState('');
    const [[selectedRecordingIds], setSelectedRecordingIds] = useState<[Set<string>]>([new Set()]);

    const selectionSize = selectedRecordingIds.size + selectedStreemshotIds.size;
    const selectionVisible = selectionSize > 0 || isMobile;

    const [showVideoPreviewInfoCard, setShowVideoPreviewInfoCard] = useState(false);
    const [videoPreviewTermsAccepted, setVideoPreviewTermsAccepted] = useState(false);
    const browserCanPlayPreview = isChrome();

    const somePreviewsReady = isPreviewReady(recordings);
    const someRecordingsReady = isRecordingReady(recordings);
    const someRecordingsPending = isRecordingPending(recordings);
    const showVideoPreview =
        videoPreviewEnabled &&
        videoPreviewTermsAccepted &&
        somePreviewsReady &&
        !someRecordingsReady;
    const streemshotsImageDataRef = useRef<StreemshotImageData>({});

    const rawTranscription = useRawTranscription(transcriptionArtifact?.transcription?.url ?? null);

    useEffect(() => {
        log.info(`Media gallery is rendering ${streemshots.length} streemshots`);
    }, [streemshots.length]);

    useEffect(() => {
        const streemshotImageDataCache = streemshotsImageDataRef.current;
        return function dataUrlCleanup() {
            // Clean url's data from browser
            const keys = Object.keys(streemshotImageDataCache);
            keys.forEach(key => {
                const { dataURL } = streemshotImageDataCache[key];
                URL.revokeObjectURL(dataURL);
            });
        };
    }, [streemshotsImageDataRef]);

    const handleArtifactSelect = (artifactType: WallItem.ArtifactType, artifactSid: string) => {
        setErrorMessage('');
        const toggleInclusion = (set: Set<string>) => {
            const included = set.delete(artifactSid);
            if (!included) {
                set.add(artifactSid);
            }

            return set;
        };

        switch (artifactType) {
            case WallItem.ArtifactType.RECORDING:
                setSelectedRecordingIds([toggleInclusion(selectedRecordingIds)]);
                break;
            case WallItem.ArtifactType.STREEMSHOT:
                setSelectedStreemshotIds([toggleInclusion(selectedStreemshotIds)]);
                break;
            default:
                log.error('Attempted to select artifact of unsupported type ' + artifactType);
                return;
        }
    };

    const clearSelection = () => {
        setSelectedRecordingIds([new Set()]);
        setSelectedStreemshotIds([new Set()]);
    };

    return useObserver(() => {
        return (
            <section data-testid="media-gallery">
                <MediaGalleryHeader as="h2" size="mediumLarge">
                    Media Gallery
                </MediaGalleryHeader>
                <MediaGalleryGrid>
                    <TimeStampControllerProvider value={new TimeStampController(recordings)}>
                        {recordings.length > 0 && (
                            <VideoContainer hideBookmarks={readOnly && bookmarks.length === 0}>
                                {someRecordingsPending && !videoPreviewEnabled && (
                                    // If the company setting for video preview is FALSE and the recording is pending we only want a skeleton.
                                    <Skeleton></Skeleton>
                                )}
                                {someRecordingsPending && videoPreviewEnabled && !showVideoPreview && (
                                    // If the company setting for video preview is TRUE, the recording is pending, and the preview is NOT ready we should only see the skeleton.
                                    // Once the preview is ready, then we should see a skeleton and a preview button.
                                    // Once we click the preview button, we should see the skeleton and the video preview info card.
                                    // Once we accept terms, the skeleton and info card should disappear and we should see the video preview.
                                    <>
                                        <Skeleton>
                                            {somePreviewsReady &&
                                                browserCanPlayPreview &&
                                                !showVideoPreviewInfoCard && (
                                                    <PreviewButton
                                                        onClick={() =>
                                                            setShowVideoPreviewInfoCard(true)
                                                        }
                                                    />
                                                )}
                                            {somePreviewsReady && !browserCanPlayPreview && (
                                                <UnsupportedBrowserInfoCard />
                                            )}
                                            {somePreviewsReady &&
                                                showVideoPreviewInfoCard &&
                                                !videoPreviewTermsAccepted && (
                                                    <VideoPreviewInfoCard
                                                        handleOkay={() =>
                                                            setVideoPreviewTermsAccepted(true)
                                                        }
                                                        handleCancel={() =>
                                                            setShowVideoPreviewInfoCard(false)
                                                        }
                                                    />
                                                )}
                                        </Skeleton>
                                    </>
                                )}
                                {showVideoPreview &&
                                    recordings.map(recording => (
                                        <RecordingTrackThumbnail
                                            canSelect={false}
                                            isSelected={false}
                                            isSelectVisible={selectionVisible}
                                            key={`preview-${recording.id}`}
                                            artifact={recording}
                                            onPlay={() =>
                                                recordArtifactViewed({
                                                    artifactSid: recording.id,
                                                    roomSid: detailSession.roomId.toString(),
                                                    recordingTrack: {},
                                                })
                                            }
                                        />
                                    ))}
                                {someRecordingsReady &&
                                    recordings.map(recording => {
                                        return (
                                            <RecordingThumbnail
                                                canSelect={authStore.isAdmin}
                                                isSelected={selectedRecordingIds.has(recording.id)}
                                                isSelectVisible={selectionVisible}
                                                key={recording.id}
                                                onSelect={() =>
                                                    handleArtifactSelect(
                                                        WallItem.ArtifactType.RECORDING,
                                                        recording.id,
                                                    )
                                                }
                                                artifact={recording}
                                                transcriptionArtifact={transcriptionArtifact}
                                                onPlay={() =>
                                                    recordArtifactViewed({
                                                        artifactSid: recording.id,
                                                        roomSid: detailSession.roomId.toString(),
                                                        recording: {},
                                                    })
                                                }
                                            />
                                        );
                                    })}
                                {recordings.length > 0 && !(readOnly && bookmarks.length === 0) && (
                                    <BookmarkSection>
                                        <BookmarksHeaderContainer>
                                            <AppText
                                                as="h3"
                                                headingFontFamily
                                                style={{ fontSize: '18px' }}
                                            >
                                                Video Bookmarks
                                            </AppText>
                                        </BookmarksHeaderContainer>
                                        {callStartTime && !someRecordingsPending && !readOnly && (
                                            <AddNewBookmark
                                                addBookmark={bookmark =>
                                                    detailSession.bookmarks.addBookmark(bookmark)
                                                }
                                            />
                                        )}
                                        <BookmarkContainer>
                                            {bookmarks.length > 0 &&
                                                callStartTime &&
                                                bookmarks
                                                    .sort((a, b) => {
                                                        const aCreatedAtTime =
                                                            a.bookmark.timestamp?.seconds.low +
                                                            a.bookmark.timestamp?.nanos /
                                                                1000000000;
                                                        const bCreatedAtTime =
                                                            b.bookmark.timestamp?.seconds.low +
                                                            b.bookmark.timestamp?.nanos /
                                                                1000000000;
                                                        if (aCreatedAtTime > bCreatedAtTime) {
                                                            return 1;
                                                        } else {
                                                            return -1;
                                                        }
                                                    })
                                                    .map(bookmark => (
                                                        <MediaGalleryBookmark
                                                            enableToolTip={someRecordingsPending}
                                                            disableTimestamp={someRecordingsPending}
                                                            key={bookmark.id}
                                                            bookmarkArtifact={bookmark}
                                                            isBookmarkOwner={
                                                                bookmark.ownerUserId ===
                                                                authStore.userId
                                                            }
                                                            updateBookmark={(label: string) => {
                                                                const newBookmark = Object.assign(
                                                                    bookmark.bookmark,
                                                                );
                                                                detailSession.bookmarks.updateBookmark(
                                                                    bookmark.id,
                                                                    { ...newBookmark, label },
                                                                );
                                                            }}
                                                            deleteBookmark={async () => {
                                                                await detailSession.bookmarks.deleteBookmark(
                                                                    bookmark.id,
                                                                );
                                                            }}
                                                        />
                                                    ))}
                                        </BookmarkContainer>
                                    </BookmarkSection>
                                )}
                            </VideoContainer>
                        )}
                        {rawTranscription && transcriptionsEnabled && (
                            <CallTranscription rawTranscription={rawTranscription} />
                        )}
                        {streemshots.map(streemshot => {
                            return (
                                <StreemshotThumbnail
                                    canClickTimeStamp={someRecordingsReady && recordings.length > 0}
                                    canSelect={true}
                                    imageDataRef={streemshotsImageDataRef}
                                    isSelected={
                                        selectedStreemshotIds.has(streemshot.id) ||
                                        selectedStreemshotIds.has(streemshot?.streemshotId)
                                    }
                                    isSelectVisible={selectionVisible}
                                    key={streemshot.id}
                                    onSelect={() =>
                                        handleArtifactSelect(
                                            WallItem.ArtifactType.STREEMSHOT,
                                            streemshot?.streemshotId || streemshot.id,
                                        )
                                    }
                                    streemshot={streemshot}
                                />
                            );
                        })}
                    </TimeStampControllerProvider>
                </MediaGalleryGrid>
                <MediaGallerySelected
                    detailSession={detailSession}
                    streemshots={streemshots}
                    selectedStreemshotIds={selectedStreemshotIds}
                    recordings={recordings}
                    selectedRecordingIds={selectedRecordingIds}
                    streemshotsImageDataRef={streemshotsImageDataRef}
                    selectionSize={selectionSize}
                    isMobile={isMobile}
                    selectionVisible={selectionVisible}
                    readOnly={readOnly}
                    onUnselect={() => {
                        setErrorMessage('');
                        clearSelection();
                    }}
                    onSelectAll={() => {
                        setErrorMessage('');
                        setSelectedStreemshotIds([new Set(streemshots.map(ss => ss.id))]);
                    }}
                    clearSelection={clearSelection}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    logger={log}
                />
            </section>
        );
    });
};

const MediaGalleryGrid = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gridAutoRows: '400px',
    gridAutoFlow: 'dense',
    gridGap: '16px',
});

const BookmarkContainer = styled.div`
    overflow: auto;
    padding: 0 40px 0 32px;

    @media (max-width: 1075px) {
        padding: 0px;
    }
`;

const BookmarksHeaderContainer = styled(Box)`
    margin: 0px 0px 20px 32px;
    @media (max-width: 1075px) {
        margin: 0px 0px 20px 0px;
    }
`;

const VideoContainer = styled('div')(({ hideBookmarks }: { hideBookmarks: boolean }) => ({
    display: 'flex',
    overflow: 'hidden',
    gridColumn: hideBookmarks ? 'span 3' : 'span 4',
    '@media (max-width: 1075px)': {
        gridRow: hideBookmarks ? 'auto' : 'span 2',
        gridColumn: '1 / -1',
        flexDirection: 'column',
    },
}));

const BookmarkSection = styled.div`
    position: relative;
    grid-column: span 2;
    display: flex;
    flex: 1 0 400px;
    max-width: 460px;
    overflow: hidden;
    flex-direction: column;

    @media (max-width: 1075px) {
        margin-top: 16px;
        flex-grow: 0;
        flex-shrink: 1;
        max-width: 100%;
    }
`;

const MediaGalleryHeader = styled(Subheader)`
    margin-bottom: 16px;
`;

interface InfoCardProps {
    handleCancel: () => void;
    handleOkay: () => void;
    Container?: FC;
}

export const VideoPreviewInfoCard: FC<InfoCardProps> = ({
    handleCancel,
    handleOkay,
    Container,
}) => {
    const ContainerToUse: FC = Container || InfoCardContainer;
    const theme = useTheme();
    return (
        <ContainerToUse>
            <Card
                decoratorPosition="side"
                style={{
                    position: 'relative',
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <div style={{ position: 'absolute', top: '30px', right: '30px' }}>
                    <IconButton
                        iconName={'CloseIcon'}
                        size="medium"
                        label="info-card-cancel-button"
                        onClick={handleCancel}
                        background="azure"
                        fill="white"
                    />
                </div>
                <Subheader>Video Preview</Subheader>
                <div
                    style={{
                        flexGrow: 1,
                        marginTop: theme.space[5],
                    }}
                >
                    <AppText>
                        This is a preview of your video and does not include any audio. We are still
                        processing your final video and it will be available ASAP.
                    </AppText>
                </div>
                <Button
                    width="150px"
                    onClick={handleOkay}
                    data-testid="info-card-okay-button"
                    style={{
                        alignSelf: 'flex-end',
                    }}
                >
                    Okay
                </Button>
            </Card>
        </ContainerToUse>
    );
};

const InfoCardContainer = styled('div')`
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    z-index: 1;
    width: 80%;
    height: 80%;
`;

const GetChromeLink = styled('a')`
    text-decoration: none;
    color: ${props => props.theme.colors.azure};

    &:visited {
        color: ${props => props.theme.colors.azure};
    }
`;

export const UnsupportedBrowserInfoCard = (): JSX.Element => {
    return (
        <div
            style={{
                display: 'flex',
                flexGrow: 1,
            }}
        >
            <Card
                decoratorPosition="side"
                style={{
                    margin: '0 20px',
                    alignSelf: 'center',
                }}
            >
                <AppText>
                    Want a preview? You can preview this video without audio by using Google Chrome.{' '}
                    <GetChromeLink
                        href="https://www.google.com/chrome/"
                        rel="noopener noreferrer"
                        target="_blank"
                        data-testid="get-chrome-link"
                    >
                        Get Chrome here.
                    </GetChromeLink>
                </AppText>
            </Card>
        </div>
    );
};

interface PreviewButtonProps {
    onClick: () => void;
}

export const PreviewButton: FC<PreviewButtonProps> = ({ onClick }) => {
    return (
        <Box mt={5}>
            <Button
                style={{ background: 'transparent' }}
                onClick={onClick}
                variant="secondary"
                data-testid="preview-video-button"
            >
                Preview
                <span style={{ transform: 'translateY(1px)', marginLeft: '5px' }}>&#9658;</span>
            </Button>
        </Box>
    );
};

export const AddNewBookmark: FC<{
    addBookmark: (bookmark: streem.api.Artifact.IBookmark) => void;
    WrapperComponent?: FC;
    buttonTextStyles?: CSSProperties;
}> = ({ addBookmark, WrapperComponent, buttonTextStyles = {} }) => {
    const { getNewBookmarkTimeStamp, convertSecondsToTimestamp } = useTimeStampController();
    const theme = useTheme();

    const Wrapper = WrapperComponent || NewBookmarkWrapper;
    const effectiveButtonTextStyles = {
        marginLeft: '6px',
        fontSize: '18px',
        ...buttonTextStyles,
    };

    return (
        <Wrapper>
            <Button
                variant="tertiary"
                onClick={() => {
                    const time = getNewBookmarkTimeStamp();
                    addBookmark({
                        timestamp: convertSecondsToTimestamp(time),
                    });
                }}
            >
                <AppIcon color={theme.colors.azure} name="AddBookmarkIcon" />{' '}
                <span style={effectiveButtonTextStyles}>Add Bookmark</span>
            </Button>
        </Wrapper>
    );
};

const NewBookmarkWrapper = styled(Row)({
    justifyContent: 'left',
    marginBottom: '12px',
    paddingLeft: '30px',
});
