import { ListStore } from '../stores/list_store';
import { StreemAPI, APITypes } from '@streem/api';
import { localStoreHookFactory } from './local_store_hook_factory';
import { ListStorePaginated } from '../stores/list_store_paginated';
import { CallLogPaginatedListStore } from '../stores/call_log_paginated_list_store';

// Wraps the listCompanies method with a ListStore for frontend use.
export const [createListCompaniesStore, ListCompaniesProvider, useListCompaniesStore] =
    localStoreHookFactory(
        'ListCompaniesStore',
        () =>
            new ListStorePaginated(StreemAPI.companies.listCompaniesRaw.bind(StreemAPI.companies), {
                filter: r => (r.companies ? r.companies : []),
                fnName: 'listCompaniesRaw',
            }),
    );

// Wraps the listUsers method with a ListStore for frontend use.
export const [createListUsersStore, ListUsersProvider, useListUsersStore] = localStoreHookFactory(
    'ListUsersStore',
    () =>
        new ListStorePaginated(
            StreemAPI.companies.listUsersForCompanyRaw.bind(StreemAPI.companies),
            {
                filter: r => (r.users ? r.users : []),
                fnName: 'listUsersForCompanyRaw',
            },
        ),
);

export const [createGetCompanyCallLogStore, GetCompanyCallLogProvider, useGetCompanyCallLogStore] =
    localStoreHookFactory(
        'GetCompanyCallLogStore',
        () =>
            new CallLogPaginatedListStore(
                StreemAPI.companies.getCompanyCallLogRaw.bind(StreemAPI.companies),
                {
                    filter: r => (r.callLogEntries ? r.callLogEntries : []),
                    fnName: 'getCompanyCallLogRaw',
                },
            ),
    );

export const [createGetUserCallLogStore, GetUserCallLogProvider, useGetUserCallLogStore] =
    localStoreHookFactory(
        'GetUserCallLogStore',
        () =>
            new CallLogPaginatedListStore(StreemAPI.users.getUserCallLogRaw.bind(StreemAPI.users), {
                filter: r => (r.callLogEntries ? r.callLogEntries : []),
                fnName: 'getUserCallLogRaw',
            }),
    );

export const [
    createGetUserFavoritesCallLogStore,
    GetUserFavoritesCallLogProvider,
    useGetUserFavoritesCallLogStore,
] = localStoreHookFactory(
    'GetUserFavoritesCallLogStore',
    () =>
        new CallLogPaginatedListStore(StreemAPI.users.getUserCallLogRaw.bind(StreemAPI.users), {
            filter: r => (r.callLogEntries ? r.callLogEntries : []),
            fnName: 'getUserCallLogRaw',
        }),
);

export const [
    createListGroupMemberMetricsStore,
    ListGroupMemberMetricsProvider,
    useListGroupMemberMetricsStore,
] = localStoreHookFactory(
    'ListGroupMemberMetricsStore',
    () =>
        new ListStorePaginated(
            StreemAPI.analytics.aggregateRoomMetricsForCompanyRaw.bind(StreemAPI.analytics),
            {
                filter: r => r.results || [],
                fnName: 'aggregateRoomMetricsForCompanyRaw',
            },
        ),
);

export const [
    createListCompanyApiKeysStore,
    ListCompanyApiKeysProvider,
    useListCompanyApiKeysStore,
] = localStoreHookFactory(
    'ListCompanyApiKeysStore',
    () =>
        new ListStore(StreemAPI.companies.listCompanyApiKeys.bind(StreemAPI.companies), {
            filter: r => (r.apiKeys ? r.apiKeys : []),
            fnName: 'listCompanyApiKeys',
        }),
);

export const [createGetCountriesStore, GetCountriesProvider, useGetCountriesStore] =
    localStoreHookFactory(
        'GetCountriesStore',
        () =>
            new ListStore(StreemAPI.countries.getCountries.bind(StreemAPI.countries), {
                filter: r => (r.countries ? r.countries : []),
                fnName: 'getCountries',
            }),
    );

export const [createListGroupUsersStore, ListGroupUsersProvider, useListGroupUsersStore] =
    localStoreHookFactory(
        'ListGroupUsers',
        () =>
            new ListStore(StreemAPI.companies.listGroupUsers.bind(StreemAPI.companies), {
                filter: r => (r.users ? r.users : []),
                fnName: 'listGroupUsers',
            }),
    );

export const [createListGroupsStore, ListGroupsProvider, useListGroupsStore] =
    localStoreHookFactory('ListGroups', (isAdmin: boolean) => {
        // NOTE: fix ST-6829. if user doesnt meet required role to call endpoint, return empty list store to avoid receiving 403 from endpoint
        if (!isAdmin) {
            return new ListStore(
                async (): Promise<APITypes.StreemApiListGroupsResponse> => {
                    return {
                        groups: [],
                        nextPageToken: undefined,
                        totalSize: 0,
                    };
                },
                { fnName: 'listGroups', filter: r => r.groups },
            );
        }

        return new ListStore(StreemAPI.groups.listGroups.bind(StreemAPI.groups), {
            filter: r => (r.groups ? r.groups : []),
            fnName: 'listGroups',
        });
    });
