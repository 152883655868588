import { useCallback, useEffect, useRef, useState } from 'react';
import {
    Box,
    styled,
    Label,
    Select,
    AppText,
    Option,
    Column,
    Row,
    AppIcon,
    useTheme,
} from '@streem/ui-react';
import { recordElementClicked } from '@streem/analytics';
import {
    getMediaDevices,
    getPreferredVideoInputId,
    setPreferredVideoInputId,
} from '@streem/toolbox';
import appLogger from '../../util/logging/app_logger';

const log = appLogger.extend('VideoOutputSelect');

function getInitialValue<T extends { deviceId: string; label: string }>(
    devices: T[],
    mediaStreamTrack: MediaStreamTrack,
    preferredCameraId: string | null,
) {
    if (preferredCameraId) {
        // check if the camera is still attached
        const attachedDeviceLabel = devices.find(
            device => device.deviceId === preferredCameraId,
        )?.label;

        if (attachedDeviceLabel) {
            return {
                label: attachedDeviceLabel,
                value: preferredCameraId,
            };
        }
    }

    const defaultCaptureDevice = devices.find(device => device.label === mediaStreamTrack.label);
    if (defaultCaptureDevice) {
        return {
            label: defaultCaptureDevice.label,
            value: defaultCaptureDevice.deviceId,
        };
    } else {
        return {
            label: '',
            value: '',
        };
    }
}
// abbreviated wih ms- prefix in case we add analytics to the in call experience in the future
const videoDropdownOpenedEvent = 'ms-video-output-select-opened';
const videoOptionSelectedEvent = 'ms-video-output-item-selected';

let mediaStream: MediaStream | null = null;

export const VideoOutputSelect = () => {
    const preferredCameraId = getPreferredVideoInputId();
    const theme = useTheme();
    const [dropdownValue, setDropdownValue] = useState<Option<string> | null>(null);
    const [renderVideo, setRenderVideo] = useState(false);
    const [videoDevices, setVideoDevices] = useState<MediaDeviceInfo[] | []>([]);
    const [{ error, errorMessage }, setError] = useState({ error: false, errorMessage: '' });
    const videoRef = useRef<HTMLVideoElement>(null);

    const getCameraFeed = useCallback(
        async (deviceId?: string, devices?: MediaDeviceInfo[]) => {
            if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
                return;
            }

            const [mediaDevice] = devices
                ? devices.filter(device => device.deviceId === deviceId)
                : videoDevices.filter(device => device.deviceId === deviceId);

            if (mediaStream) {
                // make sure we stop any active tracks before creating a new one
                mediaStream.getVideoTracks().forEach(track => track.stop());
            }

            mediaStream = await navigator.mediaDevices.getUserMedia({
                video: {
                    width: { ideal: 445 },
                    height: { ideal: 270 },
                    ...(mediaDevice && { deviceId: { exact: mediaDevice.deviceId } }),
                },
                audio: false,
            });

            return mediaStream;
        },
        [videoDevices],
    );

    const handleSelect = (option: Option<string>) => {
        getCameraFeed(option.value).then(stream => {
            if (stream) {
                videoRef.current!.srcObject = stream;
            }
        });
        setPreferredVideoInputId(option.value);
        setDropdownValue(option);
        recordElementClicked(videoOptionSelectedEvent);
    };

    useEffect(() => {
        let devices: MediaDeviceInfo[] = [];
        const setupCameraAndDevices = async () => {
            try {
                devices = await getMediaDevices('videoinput');
                setVideoDevices(devices);
                await getCameraFeed(preferredCameraId, devices);
            } catch (error) {
                setError({
                    error: true,
                    errorMessage: 'Streem requires permission to use your camera for video chat',
                });
                log.error(
                    `Unable to get camera permission on setupCameraAndDevices. Browser error: ${error}`,
                );
                return;
            }

            if (mediaStream) {
                const [mediaStreamTrack] = mediaStream.getVideoTracks();
                setDropdownValue(getInitialValue(devices, mediaStreamTrack, preferredCameraId));
                setRenderVideo(true);
                videoRef.current!.srcObject = mediaStream;
            }
        };

        setupCameraAndDevices();

        return () => {
            if (mediaStream) {
                mediaStream.getVideoTracks().forEach(track => track.stop());
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preferredCameraId]);

    return (
        <Wrapper>
            <VideoContainer>
                {renderVideo && !error && (
                    <Video data-testid="video-element" autoPlay playsInline muted ref={videoRef} />
                )}
                {error && (
                    <PermissionDeniedContainer data-testid="video-permission-denied-messages">
                        <AppText size="large" color="white" style={{ textAlign: 'center' }}>
                            {errorMessage}
                        </AppText>
                        <AppText size="small" color="white">
                            Please grant camera permission in browser settings{' '}
                        </AppText>
                    </PermissionDeniedContainer>
                )}
            </VideoContainer>

            <DropDownWrapper>
                <DropdownHeader size="medium" semibold>
                    Video
                </DropdownHeader>
                <Label size="medium" semibold>
                    Select Camera
                </Label>
                <Select
                    onMenuOpen={() => recordElementClicked(videoDropdownOpenedEvent)}
                    id="video-output-select"
                    border={true}
                    isSearchable={false}
                    placeholder="Select camera"
                    value={dropdownValue}
                    options={videoDevices?.map(device => ({
                        value: device.deviceId,
                        label: device.label,
                    }))}
                    styles={{
                        container: provided => ({
                            width: '290px',
                            ...provided,
                        }),
                    }}
                    onSelect={handleSelect}
                    menuPlacement="bottom"
                    isDisabled={videoDevices.length === 0 || error}
                />
            </DropDownWrapper>
            {error && (
                <ErrorMessageWrapper>
                    <AppIcon
                        name="CameraOffIcon"
                        slashcolor={theme.colors.red50}
                        color={theme.colors.red50}
                    />
                    <AppText
                        semibold
                        size="medium"
                        color="red50"
                        style={{ display: 'block' }}
                        data-testid="video-settings-error-message"
                    >
                        {errorMessage}
                    </AppText>
                </ErrorMessageWrapper>
            )}
        </Wrapper>
    );
};

const Wrapper = styled(Box)({
    marginBottom: '10px',
});

const VideoContainer = styled(Row)({
    justifyContent: 'left',
    alignItems: 'center',
    height: '270px',
});

const Video = styled.video(() => ({
    borderRadius: '20px',
    width: '445px',
    height: '270px',
}));

const PermissionDeniedContainer = styled(Column)(({ theme }) => ({
    justifyContent: 'center',
    alignItems: 'center',
    gap: '35px',
    borderRadius: '20px',
    width: '445px',
    height: '270px',
    backgroundColor: theme.colors.black,
}));

const DropDownWrapper = styled(Box)({
    marginTop: '16px',
});

const DropdownHeader = styled(Label)({
    display: 'block',
    margin: '5px 0px',
    fontSize: '1.1rem',
});

const ErrorMessageWrapper = styled(Row)({
    gap: '10px',
    marginTop: '1.2rem',
});
