import { Suspense, lazy, useEffect } from 'react';
import { ToastTypes } from '@streem/ui-react';
import {
    BrowserRouterProps,
    Redirect,
    Route,
    RouteComponentProps,
    RouteProps,
    Switch,
} from 'react-router-dom';
import { useGetCompanyStore } from '../hooks/detail_store_hooks';
import { useActiveCompanyCode } from '../hooks/use_active_company_code';
import { AppHistoryProvider, useInitializeAppHistory } from '../hooks/use_app_history';
import { useGlobalStore } from '../hooks/use_global_context';
import { useScreenViews } from '../hooks/use_screen_views';
import { CallDetailsPage } from '../pages/calls/call_details_page';
import { CallLogsPage } from '../pages/calls/call_logs_page';
import CompanyDetailPage from '../pages/companies/company_detail_page';
import { ServerErrorRoute } from '../pages/server_error_route';
import SuperAdminPage from '../pages/users/super_admin_page';
import TeamPage from '../pages/users/team_page';
import { Role, SESSION_SWAGA_POST_LOGIN_REDIRECT } from '../types/project.types';
import { isMobileDevice } from '../util/check_device_support';
import logger from '../util/logging/app_logger';
import { ProtectedRoute } from './protected_route';
import { EmbedCallLog } from '../components/embed/embed_call_log';
import { EmbedRoute } from './embed_route';
import { Embed } from '../components/embed/embed';
import { EmbedPostCallScreen } from '../components/embed/embed_post_call_screen';
import { useGetEmbedEnabledSettings } from '../hooks/use_get_embed_enabled_settings';
import GroupDetailsPage from '../pages/users/group_details_page';
import APIKeyPage from '../pages/companies/api_key_page';
import { GroupsRoute } from './groups_route';
import { GroupPage } from '../pages/users/group_page';
import { GroupMetricsPage } from '../pages/users/group_metrics_page';
import { GroupMetricsDetailsPage } from '../pages/users/group_metrics_details_page';
import { CompanyReportsPage } from '../pages/reports/company_reports_page';
import { useUserAuthenticatedAndSettingsLoaded } from '../hooks/use_user_authenticated_and_settings_loaded';
import { RedirectAuthenticatedSharedRoute } from './share-link-redirects';

const SalesforceNativeWrapper = lazy(() => import('../components/embed/embed_salesforce_wrapper'));

const SalesforceNativeEmbed = () => (
    <Suspense fallback={<div />}>
        <SalesforceNativeWrapper />
    </Suspense>
);

type DefaultRoutes = {
    [key in Role]?: string;
};

export const defaultRoutes: DefaultRoutes = {
    COMPANY_ADMIN: '/teamcalls',
    SUPER_ADMIN: '/companies',
    MANAGER: '/teamcalls',
    AGENT: '/mycalls',
    ANONYMOUS: '/login',
};

const RedirectAsNeeded: React.FC = () => {
    const { authStore } = useGlobalStore();

    if (authStore.isUserSignedIn) {
        // OAuth users may need to be redirected to the url they originally requested.
        const cachedRedirectUrl = window.sessionStorage.getItem(SESSION_SWAGA_POST_LOGIN_REDIRECT);

        if (cachedRedirectUrl) {
            window.sessionStorage.removeItem(SESSION_SWAGA_POST_LOGIN_REDIRECT);
            if (cachedRedirectUrl !== '/') {
                return <Redirect to={cachedRedirectUrl} />;
            }
        }
    }

    return <Redirect to={defaultRoutes[authStore.role] ?? '/error'} />;
};

const SuperAdminRoute: React.FC<RouteProps> = props => {
    return <ProtectedRoute {...props} only={['SUPER_ADMIN']} />;
};

export const AdminRoute: React.FC<RouteProps> = props => {
    return <ProtectedRoute {...props} only={['SUPER_ADMIN', 'COMPANY_ADMIN']} />;
};

export const ManagerRoute: React.FC<RouteProps> = props => {
    return <ProtectedRoute {...props} only={['SUPER_ADMIN', 'COMPANY_ADMIN', 'MANAGER']} />;
};

export const TeamMemberRoute: React.FC<RouteProps> = props => {
    return (
        <ProtectedRoute {...props} only={['SUPER_ADMIN', 'COMPANY_ADMIN', 'MANAGER', 'AGENT']} />
    );
};

export const BaseRoutes: React.FC<BrowserRouterProps> = () => {
    const appHistory = useInitializeAppHistory();
    const { uiStore } = useGlobalStore();
    const companyCode = useActiveCompanyCode();
    const companyStore = useGetCompanyStore();
    const { isEmbedView } = useGetEmbedEnabledSettings();
    const userAuthenticatedAndSettingsLoaded = useUserAuthenticatedAndSettingsLoaded();

    let EmbedComponentToUse = Embed;
    const params = new URLSearchParams(window.location.search);
    if (params.get('source') === 'salesforce') EmbedComponentToUse = SalesforceNativeEmbed;

    useEffect(() => {
        if (window.performance && performance.navigation.type === 1) {
            uiStore.setUserJustHitBrowserRefresh();
        }

        const showLoginSuccessToast =
            userAuthenticatedAndSettingsLoaded && !uiStore.userJustRefreshedPage;
        if (showLoginSuccessToast) {
            companyStore.fetch(companyCode).then(() => {
                uiStore.addToast(
                    {
                        content: `Logged into ${companyStore.result?.name || companyCode}`,
                        flavor: ToastTypes.SUCCESS,
                        id: 'login-success',
                        timeout: 6000,
                    },
                    isEmbedView,
                );
            });
        }
        // Adding the uiStore as a dependency here is unnecessary and blows up the app
        // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
    }, [userAuthenticatedAndSettingsLoaded, companyCode, isEmbedView]);

    useEffect(() => {
        const showMobileToast =
            !uiStore.userJustRefreshedPage &&
            !window.location.pathname.includes('/share/') &&
            isMobileDevice();
        if (showMobileToast) {
            logger.warn('Logged in with a mobile device');
            uiStore.addToast({
                content:
                    "Uh-oh. You're using the Streem desktop app from a mobile device—some features might not work as expected. Please log-in from a desktop computer (or the iOS App) for best results.",
                flavor: ToastTypes.CAUTION,
                id: 'mobile-device-warning',
            });
        }
        // Adding the uiStore as a dependency here is unnecessary and blows up the app
        // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
    }, []);

    useScreenViews();

    return (
        <AppHistoryProvider value={appHistory}>
            <Switch>
                <SuperAdminRoute path="/companies" component={SuperAdminPage} exact={true} />
                <SuperAdminRoute
                    path="/companies/:company/team"
                    component={TeamPage}
                    exact={true}
                />
                <SuperAdminRoute
                    path="/companies/:company/teamcalls"
                    component={CallLogsPage}
                    exact={true}
                />
                <SuperAdminRoute
                    path={[
                        '/companies/:company/settings',
                        '/companies/:company/settings/dev',
                        '/companies/:company/customizations',
                        '/companies/:company/featurelab',
                    ]}
                    component={CompanyDetailPage}
                    exact={true}
                />
                <SuperAdminRoute
                    path="/companies/:company/api"
                    component={APIKeyPage}
                    exact={true}
                />
                <SuperAdminRoute
                    path={[
                        '/companies/:company/teamcalls/:roomSid',
                        '/companies/:company/mycalls/:roomSid',
                        '/companies/:company/teamcalls/:roomSid/artifact/:artifactSid',
                        '/companies/:company/mycalls/:roomSid/artifact/:artifactSid',
                    ]}
                    component={CallDetailsPage}
                    exact={true}
                />
                <AdminRoute
                    path={['/settings', '/customizations', '/featurelab']}
                    component={CompanyDetailPage}
                    exact={true}
                />
                <GroupsRoute
                    path={['/groups', '/companies/:company/groups']}
                    exact={true}
                    component={GroupMetricsPage}
                    alternativeComponent={GroupPage}
                />
                <GroupsRoute
                    path={['/groups/:groupName', '/companies/:company/groups/:groupName']}
                    exact={true}
                    component={GroupMetricsDetailsPage}
                    alternativeComponent={GroupDetailsPage}
                />
                <ManagerRoute path="/reports" component={CompanyReportsPage} exact={true} />
                <AdminRoute path="/team" component={TeamPage} exact={true} />
                <AdminRoute path={'/teamcalls'} component={CallLogsPage} exact={true} />
                <TeamMemberRoute path={'/mycalls'} component={CallLogsPage} exact={true} />
                <TeamMemberRoute path={'/myfavorites'} component={CallLogsPage} exact={true} />
                <TeamMemberRoute path={'/callLog'} component={RedirectAsNeeded} exact={true} />
                <TeamMemberRoute
                    path={[
                        '/teamcalls/:roomSid',
                        '/teamcalls/:roomSid/artifact/:artifactSid',
                        '/mycalls/:roomSid',
                        '/mycalls/:roomSid/artifact/:artifactSid',
                        '/myfavorites/:roomSid',
                        '/myfavorites/:roomSid/artifact/:artifactSid',
                    ]}
                    component={CallDetailsPage}
                    exact={true}
                />
                <EmbedRoute path="/embed" exact component={EmbedComponentToUse} />
                <EmbedRoute path="/embed/:roomSid/postcall" exact component={EmbedPostCallScreen} />
                <EmbedRoute path="/streems" exact component={EmbedCallLog} />
                <EmbedRoute
                    path={[
                        '/embed/:roomSid',
                        '/embed/:roomSid/artifact/:artifactSid',
                        '/streems/:roomSid',
                        '/streems/:roomSid/artifact/:artifactSid',
                    ]}
                    component={CallDetailsPage}
                    exact={true}
                />
                <Route
                    path={[
                        '/callLog/details/:roomSid',
                        '/callLog/details/:roomSid/artifact/:artifactSid',
                        '/share/details/:roomSid',
                        '/share/details/:roomSid/artifact/:artifactSid',
                    ]}
                    component={RedirectAuthenticatedSharedRoute}
                    exact={true}
                />
                <Route path="/" exact={true} component={RedirectAsNeeded} />
                <Route path="/error" exact={true} component={ServerErrorRoute} />
                <Route>
                    {({ location }: RouteComponentProps) => {
                        logger.error(`404: Requested ${location.pathname}`);
                        return <Redirect to="/404" />;
                    }}
                </Route>
            </Switch>
        </AppHistoryProvider>
    );
};
