import { useCallback, useEffect } from 'react';

import { useGetUserFavoritesCallLogStore } from './list_store_hooks';
import { useAppHistory } from './use_app_history';
import { CancellablePromise } from '../types/project.types';
import { useGlobalStore } from './use_global_context';
import { CALL_LOG_PAGE_SIZE } from '../util/constants';

export const useUpdateMyFavoritesCallLog = (
    userFavoritesCallLogStore: ReturnType<typeof useGetUserFavoritesCallLogStore>,
    searchString: string,
): void => {
    const appHistory = useAppHistory();
    const { authStore } = useGlobalStore();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateCallLog = useCallback(
        // create debounced call log update function to prevent multiple requests from being made in quick succession
        (userFavoritesCallLogStore, userId, searchString, appHistory) => {
            const previousLocationPathname = appHistory[appHistory.length - 1];
            const navigatingFromCallDetailPage =
                previousLocationPathname && previousLocationPathname.includes('rm_');
            const previousListFilter = userFavoritesCallLogStore.baseRequest?.filter;

            let promise: CancellablePromise<void>;

            if (navigatingFromCallDetailPage && userFavoritesCallLogStore.results.length !== 0) {
                promise = userFavoritesCallLogStore.refresh();
            } else {
                promise = userFavoritesCallLogStore.fetchFirstPage(
                    {
                        userSid: userId,
                        pageSize: CALL_LOG_PAGE_SIZE,
                        filter: searchString.trim(),
                        favoritesOnly: true,
                    },
                    {
                        // Conditionally display cached data if we're navigating from the call detail page
                        // Show loading spinner while fetching new results if the cached data used a filter
                        clearCache: !!previousListFilter,
                    },
                );
            }
            return () => {
                promise.cancel();
            };
        },
        [],
    );

    useEffect(() => {
        updateCallLog(userFavoritesCallLogStore, authStore.userId, searchString, appHistory);
        // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
    }, [userFavoritesCallLogStore, searchString, authStore]);
};
