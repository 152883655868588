import { FC } from 'react';

import { VertNavButtonsWrapper, Wrapper } from '@streem/ui-react';

const BasicVerticalNavBar: FC = () => {
    return (
        <Wrapper expanded={false}>
            <VertNavButtonsWrapper
                data-testid="basic-vertical-nav"
                expanded={false}
                style={{ justifyContent: 'flex-end' }}
            ></VertNavButtonsWrapper>
        </Wrapper>
    );
};

export { BasicVerticalNavBar };
