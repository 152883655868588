import { getCompanyCodeFromUrl, getCompanyRegistration } from '@streem/toolbox';
import { useState, useEffect } from 'react';

import config from '../config';
import appLogger from '../util/logging/app_logger';

const log = appLogger.extend('useGetCompanyRegistration');

export interface UseGetCompanyRegistrationResult {
    error: boolean;
    regionalApiUrl: string;
    regionCode: string;
    wallUrl: string;
}

export const useGetCompanyRegistration = (): UseGetCompanyRegistrationResult => {
    const [{ regionalApiUrl, regionCode, wallUrl }, setRegionDetails] = useState({
        regionalApiUrl: null,
        regionCode: null,
        wallUrl: null,
    });
    const [error, setError] = useState(false);

    useEffect(() => {
        async function fetchCompanyRegistration(companyCode: string) {
            try {
                const { apiUrl, companyRegistration, wallUrl } = await getCompanyRegistration({
                    companyCode,
                    globalDomain: config.globalDomain,
                });
                setRegionDetails({
                    regionalApiUrl: apiUrl,
                    regionCode: companyRegistration.regionCode,
                    wallUrl: wallUrl,
                });
            } catch (e) {
                log.error(e);
                setError(true);
            }
        }

        fetchCompanyRegistration(getCompanyCodeFromUrl());
    }, []);

    return {
        error,
        regionalApiUrl,
        regionCode,
        wallUrl,
    };
};
