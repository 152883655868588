import { TabbedComponentContainer, PageLink, TabLink, Box } from '@streem/ui-react';
import { CallDetailPhotosTab } from './call_detail_photos_tab';
import { CallDetailSummaryTab } from './call_detail_summary_tab';
import { CallDetailDetailTab } from './call_details/call_detail_detail_tab';
import { TimeStampControllerProvider } from '../../../hooks/use_timestamp_controller';
import { TimeStampController } from '../../../util/timestamp_controller';
import { useDetailSession } from '../../../hooks/detail_session_hooks';
import { useObservable } from '@streem/sdk-react';
import { CallDetailTab } from '../../../types/call_detail.types';
import {
    useInitializeCallDetailTabs,
    CallDetailTabsProvider,
} from '../../../hooks/use_call_detail_tabs';

export const CallDetailTabs = ({ readOnly }: { readOnly?: boolean }): JSX.Element => {
    const detailSession = useDetailSession();
    const [recordings = []] = useObservable(detailSession.recording.recordings);

    return (
        <TimeStampControllerProvider value={new TimeStampController(recordings)}>
            <TabViewWithCallDetailTabs readOnly={readOnly} />
        </TimeStampControllerProvider>
    );
};

const TabViewWithCallDetailTabs = ({ readOnly }: { readOnly?: boolean }): JSX.Element => {
    // useInitializeCallDetailTabs needs to be called after the TimeStampControllerProvider is set up
    const callDetailTabContext = useInitializeCallDetailTabs();
    const {
        selectedTab,
        onClickTab: onClickFn,
        isTabActive: isActive,
        summaryTabElementRef, // This will be passed in to the Summary TabLink
    } = callDetailTabContext;

    const tabLinks: TabLink[] = [
        {
            link: () => (
                <Box marginRight={5} key="summary" ref={summaryTabElementRef}>
                    <PageLink
                        onClick={onClickFn(CallDetailTab.SUMMARY)}
                        title="Summary"
                        active={isActive(CallDetailTab.SUMMARY)}
                    />
                </Box>
            ),
        },
        {
            link: () => (
                <Box marginRight={5} key="photos">
                    <PageLink
                        onClick={onClickFn(CallDetailTab.PHOTOS)}
                        title="Photos"
                        active={isActive(CallDetailTab.PHOTOS)}
                    />
                </Box>
            ),
        },
        {
            link: () => (
                <Box marginRight={5} key="call-details">
                    <PageLink
                        onClick={onClickFn(CallDetailTab.CALL_DETAILS)}
                        title="Call Details"
                        active={isActive(CallDetailTab.CALL_DETAILS)}
                    />
                </Box>
            ),
        },
    ];

    return (
        <CallDetailTabsProvider value={callDetailTabContext}>
            <TabView selectedTab={selectedTab} tabLinks={tabLinks} readOnly={readOnly} />
        </CallDetailTabsProvider>
    );
};

interface TabViewProps {
    selectedTab: CallDetailTab;
    tabLinks: TabLink[];
    readOnly?: boolean;
}
const TabView = ({ selectedTab, tabLinks, readOnly }: TabViewProps): JSX.Element => {
    // All tabs components are included in the DOM, but only 1 of the 3 is visible at a time.  This
    //      makes it easier to:
    //      - maintain the state of each tab when switching between them
    //      - navigate from a streemshot timestamp (in the Photo tab) to the video element (in the Summary tab)
    return (
        <TabbedComponentContainer
            tabLinks={tabLinks}
            data-testid="call-details-tabs"
            tabsPaddingBottom="2.5rem"
            containerMarginX={0}
            overflowY="hidden"
        >
            <CallDetailSummaryTab
                readOnly={readOnly}
                hiddenYn={selectedTab !== CallDetailTab.SUMMARY}
            />
            <CallDetailPhotosTab hiddenYn={selectedTab !== CallDetailTab.PHOTOS} />
            <CallDetailDetailTab hiddenYn={selectedTab !== CallDetailTab.CALL_DETAILS} />
        </TabbedComponentContainer>
    );
};
