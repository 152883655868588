import { Recording, Streemshot } from '@streem/sdk-core';
import { useMediaBreakpoint, useObservable } from '@streem/sdk-react';
import { useEffect, useState } from 'react';
import { toggleInclusionInSet } from '../components/call_details/tabs/photos/utils';
import { OnClickTimeStamp } from '../components/media_gallery/thumbnail';
import { useDetailSession } from './detail_session_hooks';
import { useCallDetailTabs } from './use_call_detail_tabs';
import { useTimeStampController } from './use_timestamp_controller';

interface UsePhotosReturn {
    streemshots: Streemshot[];
    recordings: Recording[];
    selectedStreemshotIds: Set<string>;
    onClickTimeStamp: OnClickTimeStamp;
    handleArtifactSelect: (artifactSid: string) => void;
    clearSelectedStreemshots: () => void;
    selectAllStreemshots: () => void;
    errorMessage: string;
    setErrorMessage: (errorMessage: string) => void;
    isMobile: boolean;
    isVideoReadyYn: boolean;
    readOnly?: boolean;
}

export const usePhotos = (): UsePhotosReturn => {
    const detailSession = useDetailSession();
    const { isMobile } = useMediaBreakpoint();
    const { onClickTimeStamp, readOnly } = useCallDetailTabs();
    const timeStampController = useTimeStampController();

    const [streemshots = []] = useObservable(detailSession.streemshots.responses);
    const [recordings = []] = useObservable(detailSession.recording.recordings);

    const [errorMessage, setErrorMessage] = useState('');
    const [[selectedStreemshotIds], setSelectedStreemshotIds] = useState<[Set<string>]>([
        new Set(),
    ]);
    const [isVideoReadyYn, setIsVideoReadyYn] = useState(false);

    useEffect(() => {
        const hasVideoElement = timeStampController.hasVideoElement;
        const hasVideoArtifact = timeStampController.hasVideoArtifact;

        if (hasVideoElement && hasVideoArtifact) {
            setIsVideoReadyYn(true);
        }
    }, [timeStampController]);

    const handleArtifactSelect = (artifactSid: string) => {
        setErrorMessage('');
        const updatedSet = toggleInclusionInSet(selectedStreemshotIds, artifactSid);
        setSelectedStreemshotIds([updatedSet]);
    };
    const clearSelectedStreemshots = () => {
        setErrorMessage('');
        setSelectedStreemshotIds([new Set()]);
    };
    const selectAllStreemshots = () => {
        setErrorMessage('');
        setSelectedStreemshotIds([new Set(streemshots.map(s => s.id))]);
    };

    return {
        streemshots,
        recordings,
        onClickTimeStamp,
        handleArtifactSelect,
        clearSelectedStreemshots,
        selectAllStreemshots,
        errorMessage,
        setErrorMessage,
        isMobile,
        selectedStreemshotIds,
        isVideoReadyYn,
        readOnly,
    };
};
