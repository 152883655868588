import { VideoTile, VideoTileProps } from './video_tile';
import { Bookmarks, BookmarksProps } from './bookmarks';
import { useState } from 'react';
import { useDetailSession } from '../../../../hooks/detail_session_hooks';
import { useObservable } from '@streem/sdk-react';
import { SummarySectionWrapper } from '../shared_styled_components';

interface VideoAndBookmarksProps {
    readOnly?: boolean;
}
export const VideoAndBookmarks = ({ readOnly }: VideoAndBookmarksProps): JSX.Element => {
    const [selectedRecordingIds, setSelectedRecordingIds] = useState<string[]>([]);
    const detailSession = useDetailSession();
    const [recordings = []] = useObservable(detailSession.recording.recordings);
    const roomSid = detailSession.roomId.toString();

    const handleSelectRecording = (recordingId: string) => {
        if (selectedRecordingIds.includes(recordingId)) {
            setSelectedRecordingIds(selectedRecordingIds.filter(id => id !== recordingId));
        } else {
            setSelectedRecordingIds([...selectedRecordingIds, recordingId]);
        }
    };
    const clearRecordings = () => setSelectedRecordingIds([]);
    const selectAllRecordings = () => setSelectedRecordingIds(recordings.map(r => r.id));

    return (
        <VideoAndBookmarksView
            selectedRecordingIds={selectedRecordingIds}
            handleSelectRecording={handleSelectRecording}
            recordings={recordings}
            roomSid={roomSid}
            readOnly={readOnly}
            clearRecordings={clearRecordings}
            selectAllRecordings={selectAllRecordings}
        />
    );
};

type VideoAndBookmarksViewProps = VideoTileProps & BookmarksProps;
const VideoAndBookmarksView = ({
    selectedRecordingIds,
    handleSelectRecording,
    clearRecordings,
    selectAllRecordings,
    recordings,
    roomSid,
    readOnly,
}: VideoAndBookmarksViewProps): JSX.Element => {
    return (
        <SummarySectionWrapper data-testid="video-and-bookmarks">
            <VideoTile
                selectedRecordingIds={selectedRecordingIds}
                handleSelectRecording={handleSelectRecording}
                clearRecordings={clearRecordings}
                selectAllRecordings={selectAllRecordings}
                recordings={recordings}
                roomSid={roomSid}
                readOnly={readOnly}
            />
            <Bookmarks readOnly={readOnly} />
        </SummarySectionWrapper>
    );
};
