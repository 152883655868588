import { createContext, Ref, useContext, useRef, useState } from 'react';
import { OnClickTimeStamp } from '../components/media_gallery/thumbnail';
import { CallDetailTab } from '../types/call_detail.types';

interface CallDetailTabsContext {
    readOnly?: boolean;
    selectedTab: CallDetailTab;
    onClickTab: (tab: CallDetailTab) => () => void;
    onClickTimeStamp: OnClickTimeStamp;
    isTabActive: (tab: CallDetailTab) => boolean;
    summaryTabElementRef: Ref<HTMLDivElement | undefined>; // Tab element containing "Summary" label
}

const callDetailTabsContext = createContext<CallDetailTabsContext | undefined>(undefined);

export const useInitializeCallDetailTabs = (readOnly?: boolean): CallDetailTabsContext => {
    const [selectedTab, setSelectedTab] = useState<CallDetailTab>(CallDetailTab.SUMMARY);
    const summaryTabElementRef: Ref<HTMLDivElement> = useRef();

    const onClickTab = (tabToClick: CallDetailTab) => () => {
        if (selectedTab !== tabToClick) {
            setSelectedTab(tabToClick);
        }
    };
    const isTabActive = (tabToCheck: CallDetailTab) => selectedTab === tabToCheck;
    // This function is passed to the Photo tab to allow the user to click on a timestamp for a
    //   streemshot.  It switches to the Summary tab, scrolls to the top and jumps the video to the
    //   timestamp.
    const onClickTimeStamp: OnClickTimeStamp = (timestamp, timeStampController) => {
        timeStampController.jumpVideoToTimeStamp(timestamp);
        if (selectedTab !== CallDetailTab.SUMMARY) {
            setSelectedTab(CallDetailTab.SUMMARY);
        }

        if (summaryTabElementRef.current) {
            // `block: end` tries to scroll "Summary" to the bottom of the viewport.  The result
            //        is to scroll to the top of the page.
            summaryTabElementRef.current.scrollIntoView({ block: 'end' });
        }
    };

    return {
        readOnly,
        selectedTab,
        onClickTab,
        onClickTimeStamp,
        isTabActive,
        summaryTabElementRef,
    };
};

export function useCallDetailTabs(): CallDetailTabsContext {
    const context = useContext(callDetailTabsContext);

    if (!context) {
        throw new Error('Unable to useCallDetailTabs without parent <CallDetailTabsProvider />');
    }

    return context;
}

export const CallDetailTabsProvider = callDetailTabsContext.Provider;
