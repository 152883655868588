import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer, Observer } from 'mobx-react';
import { StreemAPI } from '@streem/api';
import { ToastTypes } from '@streem/ui-react';
import { useActiveCompanyCode } from '../../hooks/use_active_company_code';
import { createGetCompanyStore } from '../../hooks/detail_store_hooks';
import {
    ListUsersProvider,
    createListUsersStore,
    ListGroupUsersProvider,
    createListGroupUsersStore,
} from '../../hooks/list_store_hooks';
import { APINotFound } from '../../components/routing/error_handlers';
import PageHeader from '../../components/page_header/page_header';
import { useGlobalStore } from '../../hooks/use_global_context';
import { PageColumn } from '../page_column';
import AddGroupMemberFormModal from '../../forms/edit_group_form';
import GroupUsersList from '../../components/users/groups/group_details_view';
import appLogger from '../../util/logging/app_logger';
import { GroupsDetailToolbar } from '../../components/users/groups/group_details_toolbar';

const log = appLogger.extend('Groups Details Page');

const GroupDetailsPage: FC = observer(() => {
    const [showAddMemberModal, setShowAddMemberModal] = useState(false);
    const { uiStore } = useGlobalStore();
    const [selectedUser, setSelectedUser] = useState('');

    const companyCode = useActiveCompanyCode();
    const companyStore = createGetCompanyStore();
    const agentsStore = createListUsersStore();
    const groupUsersStore = createListGroupUsersStore();

    const { groupName } = useParams<any>();

    useEffect(() => {
        const promises = [
            companyStore.fetch(companyCode),
            groupUsersStore.fetch(companyCode, groupName),
        ];
        return () => promises.forEach(p => p.cancel());
    }, [companyStore, groupUsersStore, companyCode, groupName]);

    const handleAddMember = async () => {
        try {
            await StreemAPI.groups.addGroupUser(companyCode, groupName, selectedUser);
            groupUsersStore.refresh();
            uiStore.addToast({
                content: `Successfully added new group member`,
                flavor: ToastTypes.SUCCESS,
                id: `group-member-added-${selectedUser}`,
            });
            setShowAddMemberModal(false);
        } catch (e) {
            log.error('Error adding group member', e);
            uiStore.addToast({
                content: `Error adding new group member: ${e}`,
                flavor: ToastTypes.ERROR,
                id: `group-member-added-${selectedUser}`,
            });
        }
    };

    const closeModal = () => setShowAddMemberModal(s => !s);

    return (
        <ListGroupUsersProvider value={groupUsersStore}>
            <ListUsersProvider value={agentsStore}>
                <APINotFound stores={[companyStore, agentsStore, groupUsersStore]} />
                <AddGroupMemberFormModal
                    isOpen={showAddMemberModal}
                    closeModal={() => {
                        setSelectedUser('');
                        setShowAddMemberModal(false);
                    }}
                    handleSubmit={handleAddMember}
                    setSelectedUser={setSelectedUser}
                    selectedUser={selectedUser}
                    groupName={groupName}
                />
                <PageColumn dataTestId="group-details-page">
                    <GroupsDetailToolbar />
                    <Observer>
                        {() => (
                            <PageHeader
                                title={`${groupName} Group`}
                                iconName={'AddIcon'}
                                iconContext={'Add a New Group Member'}
                                handleClick={closeModal}
                            />
                        )}
                    </Observer>
                    <GroupUsersList groupName={groupName} />
                </PageColumn>
            </ListUsersProvider>
        </ListGroupUsersProvider>
    );
});

export default GroupDetailsPage;
