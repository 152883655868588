import { observable, action, reaction, IReactionDisposer, runInAction } from 'mobx';
import { StreemAPI, APITypes } from '@streem/api';
import { Logger } from '@streem/logger';
import { AuthStore } from './auth_store';
import appLogger from '../util/logging/app_logger';

export class UserStore {
    private unsubscriber: IReactionDisposer | undefined = undefined;
    private log: Logger;

    public constructor(private readonly authStore: AuthStore) {
        this.log = appLogger.extend('user-store');
    }

    // Omit sid and companySid from the User type as these details are
    // most reliably source from AuthStore.
    @observable
    public user: Omit<APITypes.StreemApiUser, 'sid' | 'companySid'> | undefined = undefined;

    @action.bound
    public setUser(user: APITypes.StreemApiUser | undefined) {
        this.user = user;
    }

    @action.bound
    public async updateUserMeta(newUserMeta: APITypes.StreemApiUpdateUserRequest) {
        if (!this.authStore.userId) {
            throw new Error('Unable to update user meta without authenticated user.');
        }

        const response = await StreemAPI.users.updateUser(this.authStore.userId, newUserMeta);
        runInAction(() => {
            this.setUser(response.user);
        });
    }

    public connect() {
        this.unsubscriber = reaction(
            () => [this.authStore.userId, this.authStore.companyCode],
            ([id, companyCode]) => {
                if (id && companyCode) {
                    this.log.info(
                        `User store connecting with userId=${id} and companyCode=${companyCode}`,
                    );
                    StreemAPI.users.getUser(id).then(response => {
                        action(() => {
                            this.setUser(response.user);
                        })();
                    });
                } else {
                    this.log.warn(
                        `Setting user to undefined, id or company code are missing, userId=${id}, companyCode=${companyCode}`,
                    );
                    this.setUser(undefined);
                }
            },
            // By default this is false. Prior to ST-7124 it was set to true.
            // Setting this to true doesn't seem necessary. The effect runs when `this.authStore.userId`
            // or `this.authStore.companyCode` change. Triggering it immediately causes the else block
            // to set `user` to `undefined`, but the initial state of `user` is already `undefined` (line 18).
            // The effect will run again once `authStore` initializes a user, at which point `userId`
            // and `companyCode` are retrieved from `authStore.user`.
            { fireImmediately: false },
        );
    }

    public disconnect() {
        if (this.unsubscriber) {
            this.unsubscriber();
        }
    }
}
