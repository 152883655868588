import { AppText, Column, NotesTextArea, styled, Theme } from '@streem/ui-react';
import { NotesHeader } from './notes_header';
import { useEditModeNotes } from '../../../../../hooks/use_edit_mode_notes';

interface EditModeNotesProps {
    placeholderText: string;
    isEditingDisabledYn?: boolean;
    testId: string;
}
export const EditModeNotes = ({
    placeholderText,
    isEditingDisabledYn,
    testId,
}: EditModeNotesProps): JSX.Element => {
    const {
        currentNote,
        handleKeyUp,
        handleInputChange,
        handleFocus,
        handleBlur,
        textAreaRef,
        isEditingYn,
        noteText,
        roomNoteCharLimit,
        errorMessage,
        hasFootBar,
        isChromeYn,
    } = useEditModeNotes();

    return (
        <>
            <Header
                characterLimit={roomNoteCharLimit}
                currentCharacterCount={currentNote?.length || 0}
                isEditingYn={isEditingYn}
            />
            <NotesWrapper>
                <NotesTextArea
                    ref={textAreaRef}
                    placeholderText={placeholderText}
                    characterLimit={roomNoteCharLimit}
                    initialNote={noteText ?? ''}
                    currentNote={currentNote}
                    isEditingYn={isEditingYn}
                    isEditingDisabledYn={isEditingDisabledYn}
                    handleKeyUp={handleKeyUp}
                    handleInputChange={handleInputChange}
                    handleFocus={handleFocus}
                    handleBlur={handleBlur}
                    errorMessage={errorMessage}
                    isBrowserChromeYn={isChromeYn}
                    showBottomBorderYn={hasFootBar}
                    data-testid={testId}
                />
            </NotesWrapper>
        </>
    );
};

interface CharacterCountProps {
    characterLimit: number;
    currentCharacterCount: number;
}
interface HeaderProps extends CharacterCountProps {
    isEditingYn: boolean;
}
const Header = ({
    isEditingYn,
    characterLimit,
    currentCharacterCount,
}: HeaderProps): JSX.Element => {
    // Only show character count when editing (EditModeNotes is displayed when there is no note text,
    //       even if the user is not editing)
    const component = isEditingYn ? (
        <CharacterCount
            currentCharacterCount={currentCharacterCount}
            characterLimit={characterLimit}
        />
    ) : undefined;

    return <NotesHeader component={component} />;
};

const CharacterCount = ({
    currentCharacterCount,
    characterLimit,
}: CharacterCountProps): JSX.Element => {
    const atLimitYn = currentCharacterCount >= characterLimit;

    return (
        <CharacterCountText color={atLimitYn ? 'red50' : 'grey50'}>
            {currentCharacterCount}/{characterLimit}
        </CharacterCountText>
    );
};

const CharacterCountText = styled(AppText)({
    paddingRight: '0.25rem',
    fontSize: Theme.text.size.medium,
    lineHeight: Theme.text.lineHeight.notQuiteAllTheWayMediumLarge, // Match the height of Call Notes text
});

export const NotesWrapper = styled(Column)({
    alignItems: 'stretch',
    backgroundColor: Theme.colors.grey01A50,
    borderRadius: '4px',
    minHeight: '16rem',
    padding: '.5rem 1rem',
    ':hover': {
        backgroundColor: Theme.colors.hoverBlue,
    },
});
