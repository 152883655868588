import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDetailSession } from './detail_session_hooks';
import { useGlobalStore } from './use_global_context';
import { useRoomOutcomeFormConfig } from './use_room_outcome_form_config';
import { StreemAPI, StreemApiListRoomOutcomeReportsResponse } from '@streem/api';
import appLogger from '../util/logging/app_logger';

const log = appLogger.extend('useRoomOutcomeReportRequired');

export const getRoomOutcomeReport = async (
    roomId: string,
): Promise<StreemApiListRoomOutcomeReportsResponse> => {
    const response = await StreemAPI.rooms.listRoomOutcomeReports(roomId);
    return response;
};

export const useRoomOutcomeReportRequired = () => {
    const detailSession = useDetailSession();
    const { companySettingsStore } = useGlobalStore();
    const { roomOutcomeFeatureEnabled } = companySettingsStore;
    const { pathname } = useLocation();
    const myCallsPage = pathname.includes('/mycalls');
    const [roomOutcomeReportState, setRoomOutcomeReportState] = useState({
        roomOutcomeReport: undefined,
        loaded: false,
    });
    const roomOutcomeFormConfig = useRoomOutcomeFormConfig();

    // only show the outcome form on the '/mycalls' page if the company flag is enabled, the company's form config exist and there is not yet a completed form artifact
    const roomOutcomeReportRequired =
        roomOutcomeFeatureEnabled &&
        roomOutcomeFormConfig &&
        Object.keys(roomOutcomeFormConfig).length &&
        !roomOutcomeReportState.roomOutcomeReport &&
        roomOutcomeReportState.loaded &&
        myCallsPage;

    useEffect(() => {
        getRoomOutcomeReport(detailSession.roomId.toString())
            .then(response => {
                const roomOutcomeReport =
                    response.roomOutcomeReportArtifacts?.length > 0
                        ? response.roomOutcomeReportArtifacts[0]
                        : undefined;
                setRoomOutcomeReportState({ roomOutcomeReport, loaded: true });
            })
            .catch(error =>
                log.error(
                    `Error fetching room outcome report: ${error.message}.  Room outcome modal will not be displayed.`,
                    error,
                ),
            );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return roomOutcomeReportRequired;
};
