import { getCompanyCodeFromUrl, getAppId } from '@streem/toolbox';
import { AppConfig } from './types/project.types';

declare global {
    interface Window {
        // @ts-ignore
        env: any;
        __ENV: {
            REACT_APP_DEFAULT_COMPANY_CODE: string;
            REACT_APP_DOMAIN: string;
            [key: string]: any;
        };
    }
}

/**
 * Get reference URL, for example for display as a subtitle on the settings page
 *
 * @param companyCode
 * @param host
 * @returns reference url for current company, ie toast.swaga.streem.team
 */
function getUrlRef(companyCode = '', host?: string): string {
    const url = host || window.location.host;
    const urlSplit = url.split('.');

    if (url.includes('prod')) {
        return `${companyCode}.${vanityDomain}`;
    } else if (url.includes('localhost')) {
        return companyCode + '.' + url;
    } else if (urlSplit[0] !== companyCode) {
        urlSplit.shift();
        const superAdminUrlRef = urlSplit.join('.');
        return companyCode + '.' + superAdminUrlRef;
    } else {
        return url;
    }
}

const retrieve = (envKey: string) =>
    window.__ENV && window.__ENV[envKey] ? window.__ENV[envKey] : process.env[envKey];

const nodeEnv = retrieve('NODE_ENV');
const reactAppEnv = retrieve('REACT_APP_ENV'); // e.g. 'dev', `special-projects`
const globalDomain = retrieve('REACT_APP_DOMAIN'); // e.g. 'demo.streem.cloud'
const appId = getAppId(
    retrieve('REACT_APP_APP_ID'),
    retrieve('REACT_APP_APP_ID_EMBED'),
    retrieve('REACT_APP_APP_ID_EMBED_SALESFORCE'),
);
const vanityDomain = retrieve('REACT_APP_VANITY_DOMAIN') || 'streempro.app';
const defaultStreemSurveyUrl = retrieve('REACT_APP_DEFAULT_STREEM_SURVEY_URL');
const companyCode = getCompanyCodeFromUrl();
const googleMapsKey = retrieve('REACT_APP_GOOGLE_MAPS_API_KEY');
const datadogRumEnabled = retrieve('REACT_APP_DATADOG_RUM_ENABLED') === 'true';
const datadogClientToken = retrieve('REACT_APP_DATADOG_CLIENT_TOKEN');
const datadogApplicationId = retrieve('REACT_APP_DATADOG_APPLICATION_ID');
const isDevelopment = nodeEnv === 'development';
const isTest = nodeEnv === 'test';

// local development config items that will never be in __ENV file
const shipLocalLogs = process.env.DEV_SHIP_LOGS == 'true';

// STREEM-13347 for now always use `us` API env for scribe logs (unless it's a local env)
function getScribeUrl(env: string | undefined) {
    const apiEnv = env?.includes('local') ? env : `${env}-us`;
    return `https://scribe.${apiEnv}.streem.cloud`;
}

const config: AppConfig = {
    globalDomain,
    vanityDomain,
    centralLoginAppName: 'workspaces',
    nodeEnv,
    googleMapsKey: googleMapsKey,
    urls: {
        globalApi: `https://global-api.${globalDomain}`,
        scribe: getScribeUrl(reactAppEnv),
    },
    appId,
    companyCode: companyCode,
    isTest,
    isProduction: nodeEnv === 'production',
    isDevelopment,
    getUrlRef,
    reactAppEnv,
    datadogClientToken,
    datadogApplicationId,
    datadogRumEnabled,
    buildTag: process.env.BUILD_TAG || window.env?.BUILD_TAG,
    defaultStreemSurveyUrl,
    shouldShipLogs: isDevelopment ? shipLocalLogs : !isTest,
    lookerHost: 'streem.cloud.looker.com',
    lookerDashboardId: 8,
};

export default config;
