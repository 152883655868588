import { FC } from 'react';
import { useTheme, Subheader } from '@streem/ui-react';
import { APITypes } from '@streem/api';
import { columnBuilder, CallLogColumnProps } from '../../components/calls/call_log_columns';
import { FlexCentered } from '../../forms/shared_form.styles';
import { useGlobalStore } from '../../hooks/use_global_context';
import { useObserver } from 'mobx-react';
import { useGetUserFavoritesCallLogStore } from '../../hooks/list_store_hooks';
import { Redirect, useHistory } from 'react-router-dom';
import { joinPaths } from '../../util/routing';
import { ListViewWithPagination } from '../../components/lists/list_view_with_pagination';
import { useUpdateMyFavoritesCallLog } from '../../hooks/use_update_my_favorites_call_log';

function buildMyCallLogColumns(props: CallLogColumnProps) {
    const cols = [
        columnBuilder.customerColumn(props),
        columnBuilder.artifactColumn(props),
        columnBuilder.durationColumn(),
        columnBuilder.dateColumn(),
    ];

    return cols;
}

interface MyFavoritesCallLogPageProps {
    searchString: string;
}

interface CallLogRow extends APITypes.StreemApiCallLogEntry {
    key: string;
}

export const MyFavoritesCallLogPage: FC<MyFavoritesCallLogPageProps> = ({ searchString }) => {
    const theme = useTheme();
    const history = useHistory();
    const { companySettingsStore } = useGlobalStore();
    const userFavoritesCallLogStore = useGetUserFavoritesCallLogStore();
    useUpdateMyFavoritesCallLog(userFavoritesCallLogStore, searchString);

    const rowClickUrl = (row: CallLogRow) => {
        const url = joinPaths(history.location.pathname, row.roomSid);
        history.push(url);
    };

    const isRowClickable = (row: CallLogRow) =>
        row.roomSid && row.callStatus === 'STATUS_SUCCESSFUL';

    return useObserver(() => {
        const callLogRecords: CallLogRow[] = userFavoritesCallLogStore.results.map(v => ({
            key: v.roomSid!,
            ...v,
        }));

        if (
            userFavoritesCallLogStore.lastError &&
            userFavoritesCallLogStore.lastError.status === 500
        ) {
            return <Redirect to="/error" />;
        }

        if (
            !userFavoritesCallLogStore.loading &&
            callLogRecords.length === 0 &&
            searchString.length
        ) {
            return (
                <FlexCentered>
                    <Subheader>No Streem calls were found for {searchString}.</Subheader>
                </FlexCentered>
            );
        }

        if (!userFavoritesCallLogStore.loading && callLogRecords.length === 0) {
            return (
                <FlexCentered>
                    <Subheader>You have not favorited any calls yet</Subheader>
                </FlexCentered>
            );
        }

        const hideGPSLocations = !companySettingsStore.gpsEnabled;
        const columns = [
            columnBuilder.favoriteCallColumn(() => userFavoritesCallLogStore.refresh(), 2_000),
            ...buildMyCallLogColumns({ theme, hideGPSLocations }),
        ];

        return (
            <ListViewWithPagination
                initialScrollPosition={userFavoritesCallLogStore.scrollPosition}
                store={userFavoritesCallLogStore}
                data-testid="my-favorites-call-log"
                loading={userFavoritesCallLogStore.loading}
                linkTo={rowClickUrl}
                canRowClick={isRowClickable}
                data={callLogRecords}
                buildRowTestId={row => 'my-favorites-call-log-row-' + row.roomSid}
                columns={columns}
                gridTemplateColumns={`minmax(100px, 150px) minmax(200px, 1fr) 200px 120px 120px`}
            />
        );
    });
};
