import { useLocation } from 'react-router-dom';
import { createGetCompanyStore } from './detail_store_hooks';
import {
    createListGroupsStore,
    useGetCompanyCallLogStore,
    useGetUserCallLogStore,
} from './list_store_hooks';
import { useActiveCompanyCode } from './use_active_company_code';
import { useGlobalStore } from './use_global_context';
import type { CallLogStore } from '../types/call_logs.types';

interface PageHeader {
    companyCode: string;
    companyStore: ReturnType<typeof createGetCompanyStore>;
    groupsStore: ReturnType<typeof createListGroupsStore>;
    isMyCallsPage: boolean;
    isTeamCallsPage: boolean;
    callLogStore: CallLogStore;
    callLogFilterEnabled: boolean;
    isAHSEnabled: boolean;
    path: string;
}

export const usePageHeader = (): PageHeader => {
    const { companySettingsStore, authStore } = useGlobalStore();

    const companyCode = useActiveCompanyCode();
    const companyStore = createGetCompanyStore();
    const groupsStore = createListGroupsStore(authStore.isAdmin || authStore.isSuperAdmin);
    const location = useLocation();
    const userCallLogStore = useGetUserCallLogStore();
    const companyCallLogStore = useGetCompanyCallLogStore();

    const isMyCallsPage = location.pathname.includes('mycalls');
    const isTeamCallsPage = location.pathname.includes('teamcalls');
    const path = location.pathname.substring(1, location.pathname.length);

    const callLogStore = isTeamCallsPage ? companyCallLogStore : userCallLogStore;

    const callLogFilterEnabled = companySettingsStore.callLogFilterEnabled;
    const isAHSEnabled = companySettingsStore.ahsVirtualExpertEnabled;

    return {
        companyCode,
        companyStore,
        groupsStore,
        isMyCallsPage,
        isTeamCallsPage,
        callLogStore,
        callLogFilterEnabled,
        isAHSEnabled,
        path,
    };
};
