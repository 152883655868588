import { StreemshotDetection } from '@streem/sdk-core';
import { DetectionsByStreemshotId } from '@streem/sdk-react';
import { AppText, Column, Row, styled, Theme } from '@streem/ui-react';
import { MouseEventHandler } from 'react';
import { History } from 'history';
import { EmptySection, Header, PhotoSectionWrapper } from '../shared_styled_components';
import { useModelAndSerialNumber } from '../../../../hooks/use_model_and_serial_number';
import { totalDetectionCount } from './utils';

export const ModelAndSerialNumber = (): JSX.Element => {
    const { sortedDetectionsByStreemshot, history } = useModelAndSerialNumber();

    return (
        <PhotoSectionWrapper>
            <Header>Model and Serial Number</Header>
            <StreemshotDetectionsView
                sortedDetectionsByStreemshot={sortedDetectionsByStreemshot}
                history={history}
            />
        </PhotoSectionWrapper>
    );
};

interface StreemshotDetectionsViewProps {
    sortedDetectionsByStreemshot: DetectionsByStreemshotId[];
    history: History<unknown>;
}
const StreemshotDetectionsView = ({
    sortedDetectionsByStreemshot,
    history,
}: StreemshotDetectionsViewProps) => {
    const detectionCount = totalDetectionCount(sortedDetectionsByStreemshot);
    if (detectionCount === 0) {
        return (
            <EmptySection data-testid="no-model-or-serial-numbers">
                <AppText>No model or serial numbers detected</AppText>
            </EmptySection>
        );
    }

    return (
        <ModelSerialNumberSectionWrapper>
            {sortedDetectionsByStreemshot.map(detection => {
                return (
                    <DetectionsForStreemshotView
                        key={detection.streemshotId}
                        modelNumbers={detection.modelNumbers}
                        serialNumbers={detection.serialNumbers}
                        streemshotRevisionId={detection.streemshotRevisionId}
                        history={history}
                    />
                );
            })}
        </ModelSerialNumberSectionWrapper>
    );
};

interface DetectionsForStreemshotViewProps {
    modelNumbers: StreemshotDetection[];
    serialNumbers: StreemshotDetection[];
    streemshotRevisionId: string;
    history: History<unknown>;
}
const DetectionsForStreemshotView = ({
    modelNumbers,
    serialNumbers,
    streemshotRevisionId,
    history,
}: DetectionsForStreemshotViewProps) => {
    return (
        <StreemshotWrapper>
            {modelNumbers.map(model => (
                <DetectionView
                    key={model.description}
                    title="Model Number"
                    description={model.description}
                    streeemshotRevisionId={streemshotRevisionId}
                    history={history}
                    data-testid={`model-number-${model.detectionId}`}
                />
            ))}
            {serialNumbers.map(serial => (
                <DetectionView
                    key={serial.description}
                    title="Serial Number"
                    description={serial.description}
                    streeemshotRevisionId={streemshotRevisionId}
                    history={history}
                    data-testid={`serial-number-${serial.detectionId}`}
                />
            ))}
        </StreemshotWrapper>
    );
};

interface DetectionViewProps {
    title: 'Model Number' | 'Serial Number';
    description: string;
    streeemshotRevisionId: string;
    history: History<unknown>;
}
const DetectionView = ({
    title,
    description,
    streeemshotRevisionId,
    history,
}: DetectionViewProps) => {
    const onClickHandler: MouseEventHandler<HTMLButtonElement> = event => {
        const url = `${location.pathname}/artifact/${streeemshotRevisionId}`;
        history.push(url);
        event.stopPropagation();
    };

    return (
        <DetectionWrapper>
            <DetectionTitle>{title}</DetectionTitle>
            <DetectionDescription onClick={onClickHandler}>{description}</DetectionDescription>
        </DetectionWrapper>
    );
};

const ModelSerialNumberSectionWrapper = styled(Column)({
    backgroundColor: Theme.colors.grey01A50,
    borderRadius: '4px',
    padding: '0 1.0rem .5rem 1.0rem',
    fontSize: Theme.text.size.medium,
    lineHeight: Theme.text.lineHeight.medium,
});

const StreemshotWrapper = styled(Column)({
    // Draws gray line to separate the detections for one streemshot from the next
    '&:not(:last-child)': {
        borderBottom: `1px solid ${Theme.colors.grey10}`,
    },
    padding: '.5rem 0',
    rowGap: '.5rem',
});

const DetectionWrapper = styled(Row)({
    alignItems: 'center',
    columnGap: '.5rem',
});

const DetectionTitle = styled.div({
    width: '50%',
    fontFamily: Theme.text.bodyFontFamily,
});

// Using button instead of <a> because it does not navigate to an href within the same page.
//     However, it is styled to look like an <a> tag, so the Button component is not used.
const DetectionDescription = styled.button({
    width: '50%',
    backgroundColor: Theme.colors.grey10,
    padding: '0 .5rem',
    borderRadius: 6,
    fontFamily: Theme.text.headingFontFamily,
    border: 'none',
    textAlign: 'unset',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: 500,
    color: Theme.colors.blue50,
    ':hover': {
        cursor: 'pointer',
    },
});
