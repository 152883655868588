/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react';
import { useGlobalStore } from './use_global_context';
import { useAppHistory } from './use_app_history';
import { CancellablePromise } from '../types/project.types';
import { UserCallLogStore } from '../types/call_logs.types';
import { CALL_LOG_PAGE_SIZE } from '../util/constants';

type MyCallLogFiltersConfig = {
    requestSourceCodes?: string[];
    searchStringFilter?: string;
};

/**
 * Updates the filters on the call log store and triggers a request for new data
 * @param callLogStore - The call log store to update
 * @param filters - The filters to apply to the call log
 */
export const useUpdateMyCallLogFilters = (
    callLogStore: UserCallLogStore,
    filters?: MyCallLogFiltersConfig,
): void => {
    const { authStore } = useGlobalStore();
    const appHistory = useAppHistory();

    const updateCallLog = useCallback(
        (callLogStore, userId, requestSourceCodes, searchStringFilter, appHistory) => {
            const previousLocationPathname = appHistory[appHistory.length - 1];
            const navigatingFromCallDetailsPageYn =
                previousLocationPathname && previousLocationPathname.includes('rm_');

            const storedResultsExist = callLogStore.results.length !== 0;

            let promise: CancellablePromise<void>;

            // If we're navigating from the call details page, we need to refresh the call log store
            if (navigatingFromCallDetailsPageYn && storedResultsExist) {
                promise = callLogStore.refresh();
                return;
            } else {
                promise = callLogStore.fetchFirstPage(
                    {
                        userSid: userId,
                        pageSize: CALL_LOG_PAGE_SIZE,
                        filter: searchStringFilter.trim(),
                        callSourceCodes: requestSourceCodes,
                    },
                    {
                        clearCache: true,
                    },
                );
            }

            return () => {
                promise?.cancel();
            };
        },
        [],
    );

    useEffect(() => {
        updateCallLog(
            callLogStore,
            authStore.userId,
            filters.requestSourceCodes,
            filters.searchStringFilter,
            appHistory,
        );
    }, [
        callLogStore,
        authStore.userId,
        filters.requestSourceCodes.toString(),
        filters.searchStringFilter,
    ]);
};
