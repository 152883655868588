import { useObservable } from '@streem/sdk-react';
import { useDetailSession } from './detail_session_hooks';
import { useGlobalStore } from './use_global_context';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import appLogger from '../util/logging/app_logger';
import { useTimeout } from './use_timeout';

export interface NotesData {
    roomNoteCharLimit: number;
    errorMessage?: string;
    noteText?: string;
    setNoteText: (noteText: string | undefined) => void;
    isEditingYn: boolean;
    setIsEditingYn: (showViewModeYn: boolean) => void;
    onClickEdit: () => void;
    onSave: (noteText: string) => Promise<void>;
    saveMessageVisibleYn: boolean;
    showSavedMessage: () => void;
}

const notesContext = createContext<NotesData | undefined>(undefined);

// Store notes in context so that state can be shared between components
export const useInitializeNotes = (): NotesData => {
    const detailSession = useDetailSession();
    const { companySettingsStore } = useGlobalStore();
    const [roomNote] = useObservable(detailSession.note.roomNote);
    const roomNoteCharLimit = companySettingsStore.roomNoteCharLimit;

    const [isEditingYn, setIsEditingYn] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [noteText, setNoteText] = useState(roomNote?.text);
    const [saveMessageVisibleYn, setShowSaveMessageVisibleYn] = useState<boolean>(false);

    // roomNote is fetched asynchronously, so we need to update the noteText when it changes
    useEffect(() => {
        setNoteText(roomNote?.text);
    }, [roomNote]);

    const onClickEdit = useCallback(() => {
        setIsEditingYn(true);
    }, [setIsEditingYn]);
    const onSave = useCallback(
        async (noteText: string) => {
            setErrorMessage(undefined);
            try {
                await detailSession.note.saveRoomNote(noteText);
            } catch (error) {
                setErrorMessage('Error attempting to edit call notes.');
                appLogger.error('Error attempting to edit call notes: ', error);
            }
        },
        [detailSession.note],
    );
    const clearSavedMessage = useCallback(() => {
        setShowSaveMessageVisibleYn(false);
    }, [setShowSaveMessageVisibleYn]);
    // Hide the saved message after a timeout.  If the component is unmounted before the timeout, the timeout is cleared
    const clearSavedMessageAfterTimeout = useTimeout(clearSavedMessage, 1000, true);

    const showSavedMessage = useCallback(() => {
        setShowSaveMessageVisibleYn(true);
        clearSavedMessageAfterTimeout();
    }, [clearSavedMessageAfterTimeout]);

    return {
        roomNoteCharLimit,
        errorMessage,
        noteText,
        setNoteText,
        isEditingYn,
        setIsEditingYn,
        onClickEdit,
        onSave,
        saveMessageVisibleYn,
        showSavedMessage,
    };
};

export const useNotes = (): NotesData => {
    const notes: NotesData | undefined = useContext(notesContext);
    if (!notes) {
        throw new Error('useNotes must be used within a NotesProvider');
    }
    return notes;
};

export const NotesProvider = notesContext.Provider;
