import {
    DetectionsByStreemshotId,
    groupDetectionsByStreemshotId,
    sortDetectionsByStreemshotId,
    useObservable,
} from '@streem/sdk-react';
import { History } from 'history';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { streem } from 'streem-sdk-protobuf';
import { useDetailSession } from './detail_session_hooks';

interface ModelAndSerialNumberReturnValue {
    sortedDetectionsByStreemshot: DetectionsByStreemshotId[];
    history: History<unknown>;
}
export const useModelAndSerialNumber = (): ModelAndSerialNumberReturnValue => {
    const detailSession = useDetailSession();
    const history = useHistory();
    const [modelSerial = []] = useObservable(
        detailSession.detection.getDetections({
            types: [
                streem.api.Detection.Type.DETECTION_TYPE_MODEL,
                streem.api.Detection.Type.DETECTION_TYPE_SERIAL,
            ],
        }),
    );
    const [streemshots = []] = useObservable(detailSession.streemshots.responses);
    // Using useMemo here because the transformation is pretty extensive. However, there is not much data
    //     since it is only detections and streemshots for a single room, so maybe it is not necessary.
    const sortedDetectionsByStreemshot = useMemo(() => {
        return sortDetectionsByStreemshotId(
            groupDetectionsByStreemshotId(modelSerial, streemshots),
        );
    }, [modelSerial, streemshots]);

    return {
        sortedDetectionsByStreemshot,
        history: history,
    };
};
