import { useCallback, useEffect, useRef } from 'react';

// Handles calling a callback after a delay.  Accepts a callback, a delay in milliseconds, and an optional flag
//    to clear the timeout when the component unmounts.  Note that if the function is called again before the
//    timeout has completed, the previous timeout is cleared and a new one is set.  (This means that only the last
//    call to the function will result in the callback being called.)
//
// @param callback - the function to call after the delay
// @param delayInMs - the delay in milliseconds
// @param clearOnUnmountYn - (optional) whether to clear the timeout when the component unmounts
// @returns a function that can be called to set the timeout
//
// Example usage:
//   const timeoutFn = useTimeout(() => { console.log('button pressed'); }, 1000);
//
//   return (<button onClick={timeoutFn}>Press me</button>);
//
// In this example, the callback will be called 1 second after the button is pressed.  If the button is pressed
//    again before the 1 second has elapsed, the previous timeout is cleared and a new one is set.  (This means
//    that 'button pressed' is only logged once, 1 second after the last button press.)
//
// If the button is pressed and the component is unmounted before the 1 second has elapsed, 'button pressed' will
//    still be logged because clearOnUnmountYn is not set to true.  If clearOnUnmountYn were set to true, the timeout
//    would be cleared when the component unmounts, and 'button pressed' would not be logged.
type Callback = () => void;
export const useTimeout = (
    callback: Callback,
    delayInMs: number,
    clearOnUnmountYn?: boolean,
): Callback => {
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        return () => {
            if (clearOnUnmountYn && timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [clearOnUnmountYn]);

    const timeoutFn = useCallback(() => {
        // Clear the timeout before setting a new one
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }

        timeoutRef.current = setTimeout(() => {
            callback();
            timeoutRef.current = null;
        }, delayInMs);
    }, [callback, delayInMs]);

    return timeoutFn;
};
