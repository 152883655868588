import { SummarySectionWrapper } from '../shared_styled_components';
import { Transcription } from './transcription';
import { CallNotes } from './notes/notes';

interface NotesAndTranscriptionsProps {
    readOnly?: boolean;
}
export const NotesAndTranscriptions = ({ readOnly }: NotesAndTranscriptionsProps): JSX.Element => {
    return (
        <SummarySectionWrapper>
            <CallNotes readOnly={readOnly} />
            <Transcription />
        </SummarySectionWrapper>
    );
};
