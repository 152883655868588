import { Recording } from '@streem/sdk-core';
import { action, computed, observable } from 'mobx';
import { streem, Long, Timestamp, google } from 'streem-sdk-protobuf';
import { StreemshotData } from '../hooks/use_fetch_streemshot_data';

export class TimeStampController {
    @observable
    private _videoArtifacts: any;
    @observable
    private _videoElements: HTMLVideoElement[] = [];

    constructor(videoArtifacts: Recording[]) {
        this._videoArtifacts = videoArtifacts;
    }

    // Video elements must first be registered before the time stamp is set on them
    @action
    public registerVideoElement(videoElement: HTMLVideoElement): void {
        if (!this._videoElements.includes(videoElement)) {
            this._videoElements.push(videoElement);
        }
        return;
    }

    public jumpVideoToTimeStamp(createdAtTime: number): void {
        if (this._videoElements.length === 0) {
            throw new Error(
                'You must register at least 1 video element before setting the time stamp',
            );
        }

        this._videoElements.forEach(videoEl => {
            videoEl.currentTime = createdAtTime - this.videoCreatedAt;
        });
    }

    @computed
    public get hasVideoArtifact(): boolean {
        return this._videoArtifacts.length > 0;
    }

    @computed
    public get hasVideoElement(): boolean {
        return this._videoElements.length > 0;
    }

    @computed
    public get videoCreatedAt(): number | undefined {
        return (
            this._videoArtifacts[0].clientCreatedAt?.seconds.low +
            this._videoArtifacts[0].clientCreatedAt?.nanos / 1000000000
        );
    }

    private get currentPlaybackTime(): number {
        return this._videoElements[0].currentTime;
    }

    private convertTimeStampToReadableString(secondsElapsed: number): string {
        const minutes = Math.floor(secondsElapsed / 60);
        const seconds = Math.floor(secondsElapsed % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    }

    public convertSecondsToTimestamp = (time: number): google.protobuf.Timestamp => {
        return new Timestamp({
            seconds: new Long(time),
            nanos: ((time * 1000) % 1000) * 1e6,
        });
    };

    public getStreemShotTimeStamp = (streemshot: StreemshotData): string => {
        const { clientCreatedAt } = streemshot;
        const streemShotCreatedAtTime =
            clientCreatedAt.seconds.low + clientCreatedAt.nanos / 1000000000;
        const secondsElapsedSinceVideoStart = streemShotCreatedAtTime - this.videoCreatedAt;
        return this.convertTimeStampToReadableString(secondsElapsedSinceVideoStart);
    };

    public getBookmarkTimeStamp = (bookmark: streem.api.Artifact.IBookmark): string => {
        const { timestamp } = bookmark;
        const bookmarkCreatedAtTime = timestamp?.seconds.low + timestamp?.nanos / 1000000000;
        const secondsElapsedSinceVideoStart = bookmarkCreatedAtTime - this.videoCreatedAt;
        return this.convertTimeStampToReadableString(secondsElapsedSinceVideoStart);
    };

    public getNewBookmarkTimeStamp = (): number => {
        return this.videoCreatedAt + this.currentPlaybackTime;
    };
}
