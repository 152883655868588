import { Grid, styled } from '@streem/ui-react';
import { ModelAndSerialNumber } from './photos/model_and_serial_number';
import { Photos } from './photos/photos';
import { tabWrapperSharedProps } from './shared_styled_components';

export const CallDetailPhotosTab = ({ hiddenYn }: { hiddenYn: boolean }): JSX.Element => {
    return (
        <PhotoTabWrapper hiddenYn={hiddenYn}>
            <Photos />
            <ModelAndSerialNumber />
        </PhotoTabWrapper>
    );
};

const PhotoTabWrapper = styled(Grid)<{ hiddenYn: boolean }>(({ hiddenYn }) => ({
    columnGap: '1.5rem',
    gridTemplateColumns: '7fr 3fr',
    ...tabWrapperSharedProps({ hiddenYn }),
}));
