import { DetectionsByStreemshotId } from '@streem/sdk-react';

export const toggleInclusionInSet = <T>(set: Set<T>, item: T): Set<T> => {
    const included = set.delete(item);
    if (!included) {
        set.add(item);
    }

    return set;
};

export const totalDetectionCount = (detectionsByStreemshot: DetectionsByStreemshotId[]): number => {
    return detectionsByStreemshot.reduce((acc, detection) => {
        return acc + detection.modelNumbers.length + detection.serialNumbers.length;
    }, 0);
};
