import { ChangeEvent, KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import { NotesData, useNotes } from './use_notes';
import { useTimeout } from './use_timeout';
import appLogger from '../util/logging/app_logger';

interface EditModeNotesValues
    extends Pick<
        NotesData,
        'isEditingYn' | 'noteText' | 'roomNoteCharLimit' | 'errorMessage' | 'saveMessageVisibleYn'
    > {
    currentNote: string;
    textAreaRef: React.MutableRefObject<HTMLTextAreaElement | undefined>;
    handleKeyUp: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
    handleInputChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    handleFocus: () => void;
    handleBlur: () => void;
    isChromeYn: boolean;
    hasFootBar: boolean;
    __setCurrentNote: (note: string) => void; // for testing
}
export const useEditModeNotes = (): EditModeNotesValues => {
    const {
        noteText,
        setNoteText,
        isEditingYn,
        setIsEditingYn,
        onSave,
        roomNoteCharLimit,
        errorMessage,
        showSavedMessage,
        saveMessageVisibleYn,
    } = useNotes();
    // currentNote includes unsaved changes in the note.  noteText is the saved note.
    const [currentNote, setCurrentNote] = useState<string>(noteText ?? '');
    const textAreaRef = useRef<HTMLTextAreaElement | undefined>();

    const resizeTextarea = useCallback(() => {
        requestAnimationFrame(() => {
            if (textAreaRef.current) {
                textAreaRef.current.style.height = '5px';
                textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight + 8}px`;
            }
        });
    }, []);

    const stopEditing = useCallback(() => {
        setIsEditingYn(false);
        resizeTextarea();
    }, [resizeTextarea, setIsEditingYn]);

    // Saving occurs at most every 500ms.  When save is initiated, some updates are
    //    made optimistically:
    //   - show the saved message
    //   - update noteText with the currentNote
    //  The updated note is saved on the wall after 500ms.
    const onSaveCallback = useCallback(() => {
        const updatedNote = currentNote?.trim() ?? '';
        if (updatedNote !== noteText) {
            setNoteText(updatedNote);
        }
        onSave(updatedNote);
    }, [currentNote, noteText, onSave, setNoteText]);
    const saveAfterTimeout = useTimeout(onSaveCallback, 500);
    const debounceSave = useCallback(() => {
        saveAfterTimeout();
        showSavedMessage(); // optimistically show the saved message
    }, [saveAfterTimeout, showSavedMessage]);

    const handleKeyUp = useCallback((event: KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Escape') {
            // Will trigger handleBlur, which wil save and stop editing
            textAreaRef.current.blur();
        }
    }, []);

    const handleInputChange = useCallback(
        (e: ChangeEvent<HTMLTextAreaElement>) => {
            const draft = e.target.value;
            const isTooLong = draft?.length >= roomNoteCharLimit;
            const updatedValue = isTooLong ? draft.slice(0, roomNoteCharLimit) : draft;
            setCurrentNote(updatedValue);
        },
        [roomNoteCharLimit, setCurrentNote],
    );

    const startEditing = useCallback(() => {
        // This should never happen, but to be defensive, check if the textarea exists
        if (!textAreaRef.current) {
            appLogger.warn('textAreaRef.current is undefined');
            return;
        }
        textAreaRef.current.focus();
        // Sets the cursor to the end of the text when there is text in the textarea
        const textLength = textAreaRef.current.value.length;
        textAreaRef.current.setSelectionRange(textLength, textLength);
        resizeTextarea();
        // Scroll to the bottom of the textarea when editing starts
        textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
    }, [resizeTextarea]);

    const handleFocus = useCallback(() => {
        if (isEditingYn) {
            return;
        }
        setIsEditingYn(true);
        startEditing();
    }, [isEditingYn, startEditing, setIsEditingYn]);

    const handleBlur = useCallback(() => {
        debounceSave();
        stopEditing(); // This happens immediately, but the save is debounced
    }, [debounceSave, stopEditing]);

    // Set the focus to the textarea when editing starts.  This should be called when the component mounts.
    //     It can't be added to the onClick handler of the edit button because the edit button is rendered
    //     by a different component and the textarea is not yet rendered when the edit button is clicked.
    useEffect(() => {
        if (isEditingYn) {
            startEditing();
        }
    }, [isEditingYn, startEditing]);

    return {
        currentNote,
        textAreaRef,
        handleKeyUp,
        handleInputChange,
        handleFocus,
        handleBlur,
        isEditingYn,
        noteText,
        roomNoteCharLimit,
        errorMessage,
        // The next 2 values match the implementation of legacy call details:
        //    https://github.com/streem/streem-app/pull/12333/files#diff-e5fb450819ddca614e5dc95148ae0866bbf2945ff1486cedf905195960bfb5a0
        isChromeYn: false,
        hasFootBar: false,
        saveMessageVisibleYn,
        __setCurrentNote: setCurrentNote,
    };
};
