import { Redirect, RouteComponentProps, useParams } from 'react-router-dom';
import { useGlobalStore } from '../hooks/use_global_context';
import { CallDetailsPage } from '../pages/calls/call_details_page';

/*
Truthtable for how we handle share links
+---------------------+------------+------------+--------------------------------------------------------+
| Anon Share Enabled? | Anon User? | Logged In? |                         Result                         |
+---------------------+------------+------------+--------------------------------------------------------+
| Y                   | Y          | -          | Go to anonymous version of call details                |
| Y                   | N          | N          | Go to anonymous version of call details                |
| Y                   | N          | Y          | Redirected to /teamcalls version of share link         |
| N                   | Y          | -          | Redirect to /login                                     |
| N                   | N          | N          | Redirect to login, redirected to share link post-login |
| N                   | N          | Y          | Redirect to login, redirected to share link post-login |
+---------------------+------------+------------+--------------------------------------------------------+
*/

export const MaybeRedirectAnonymousSharedRoute: React.FC<RouteComponentProps> =
    routeComponentProps => {
        const { authStore, configStore } = useGlobalStore();

        if (!configStore.anonymousShareEnabled) {
            return <Redirect to="/login" />;
        }

        /* TODO: this is a short-term workaround to address STREEM-7813 (https://streempro.atlassian.net/browse/STREEM-7813). We should revert back to a single check for 'authStore.isUserSignedIn` once STREEM-8083 (https://streempro.atlassian.net/browse/STREEM-8083) is merged.
         */
        const accessToken = window.sessionStorage.getItem('STREEM_ACCESS_TOKEN');
        if (authStore.isUserSignedIn || accessToken) {
            return <RedirectAuthenticatedSharedRoute {...routeComponentProps} />;
        }

        return <CallDetailsPage {...routeComponentProps} />;
    };

export const RedirectAuthenticatedSharedRoute: React.FC<RouteComponentProps> = () => {
    const { roomSid, artifactSid } = useParams<{ roomSid: string; artifactSid: string }>();
    const parts = ['/teamcalls', roomSid];

    if (artifactSid) {
        parts.push('artifact', artifactSid);
    }

    return <Redirect to={parts.join('/')} />;
};
