import { Option } from '@streem/ui-react';

export interface StreemUrls {
    globalApi: string;
    scribe: string;
}

export interface AppConfig {
    globalDomain: string;
    vanityDomain: string;
    centralLoginAppName: string;
    urls: StreemUrls;
    appId: string;
    nodeEnv: string;
    companyCode: string;
    isTest: boolean;
    isProduction: boolean;
    isDevelopment: boolean;
    getUrlRef: (companyCode: string, host?: string) => string;
    reactAppEnv: string;
    datadogClientToken: string;
    datadogApplicationId: string;
    datadogRumEnabled: boolean;
    googleMapsKey: string;
    buildTag: string;
    defaultStreemSurveyUrl: string;
    shouldShipLogs: boolean;
    lookerHost: string;
    lookerDashboardId: number;
}

export enum Scopes {
    ANONYMOUS = 'streem:anonymous',
    COMPANY_ADMIN = 'streem:company-admin',
    COMPANY_OWNER = 'streem:company-owner',
    SUPER_ADMIN = 'streem:super-admin',
    SUPPORT_READONLY = 'streem:support:read',
    USER = 'streem:user',
}

export type Role =
    | 'AGENT'
    | 'ANONYMOUS'
    | 'COMPANY_ADMIN'
    | 'COMPANY_OWNER'
    | 'DEVELOPER'
    | 'SUPER_ADMIN'
    | 'SUPPORT_READONLY'
    | 'MANAGER';

export interface Company {
    sid: string;
    code: string;
    name: string;
    active: boolean;
}

export interface CompanySetting {
    name: string;
    value: string;
}

// region Routing

export interface RouteProps {
    path: string | string[];
    component: React.ComponentType<unknown>;
    exact?: boolean;
}

export interface RouterProps {
    basename: string;
}

export interface RouterPaths {
    [path: string]: React.ComponentType<unknown>;
}

export interface CreateCompanyParameters {
    companyName: string;
    companyCode: string;
    ownerEmail: string;
    ownerName: string;
}

export const SESSION_SWAGA_POST_LOGIN_REDIRECT = 'SWAGA_POST_LOGIN_REDIRECT';
export const SESSION_SWAGA_POST_LOGOUT_SUCCESS = 'SWAGA_POST_LOGOUT_SUCCESS';

// endregion

// region Form types

export interface RoleOption extends Option<string> {
    groupName: Role;
    description: string;
    rank: number;
}

export interface UserFormConfig {
    header: string;
    cta: string;
    testId: string;
}

export enum UserFormStrategy {
    CREATE,
    EDIT_OTHER,
    EDIT_SELF,
}

// endregion Form types

export interface CancellablePromise<T> extends Promise<T> {
    cancel: () => void;
}

// region Embed types

export interface EmbedSessionParams {
    refId?: string;
    integrationId?: string;
    phone?: string;
    name?: string;
    email?: string;
    callingCode?: string;
    redirect?: string;
}

export enum EmbedSessionKeys {
    IS_EMBED = 'IS_EMBED',
    INVITE = 'EMBED_PARAMS_INVITE',
    CALL_LOG = 'EMBED_PARAMS_CALL_LOG',
}

export const SESSION_SDK_TOKEN = 'SDK_TOKEN';

/**
 * This session storage item is for users accessing Embed via an SDK token
 *
 * Its initial use is to store the salesforce app context needed publish Canvas Events to Salesforce
 */
export const SESSION_EMBED_SOURCE_CONTEXT = 'EMBED_SOURCE_CONTEXT';

// endregion Embed types
