import { RoomOutcomeFormPayload } from '../../../types/disposition.types';
import { useObservable } from '@streem/sdk-react';
import { AppIcon, AppText, Box, Button, Header, Row, styled } from '@streem/ui-react';
import { streem } from 'streem-sdk-protobuf';
import { FC } from 'react';
import {
    useCustomerExpertUserInfo,
    useDetailSession,
    useInviteType,
    useRoomDuration,
} from '../../../hooks/detail_session_hooks';
import { convertProtobufToRoomOutcome } from '../../../util/convert_room_outcome_protobuf';
import { parsePhoneNumber } from '../../../util/parse_phone_number';
import { getDateFromProtobufTimestamp } from '../../../util/protobuf';
import { InviteToStreemButton } from '../../invite/invite_to_streem_button';

/**
 * Customer details pertaining to a call log entry,
 * Intended to appear at the top of the call details view
 *
 * This supports reservations that use key/value pairs for details.  This method is
 * deprecated in favor of structured `callDetail`.
 */
export const CallDetailsCustomerDetails: FC = () => {
    const detailSession = useDetailSession();
    const dateFormat = { year: 'numeric', month: 'short', day: 'numeric' } as const;
    const timeFormat = { hour: 'numeric', minute: 'numeric' } as const;
    const [room] = useObservable(detailSession.room.room);
    const { customer, expert } = useCustomerExpertUserInfo(detailSession);
    const durationString = useRoomDuration(room?.sid);
    const inviteType = useInviteType(detailSession);

    const [roomOutcomeReport] = useObservable(detailSession.personalizations.roomOutcomeReport);

    if (!room?.openedAt || !expert) {
        return null;
    }

    const startDate = getDateFromProtobufTimestamp(room.openedAt);

    const isEmbedWorkflow =
        window.location.pathname.startsWith('/embed/') ||
        window.location.pathname.startsWith('/streems/');

    const { parsedPhone, phone } = parsePhoneNumber(customer?.phone ?? '');
    const isPhoneInvite = Boolean(inviteType === 'phone' && parsedPhone);
    const isLinkInvite = inviteType === 'link' || !isPhoneInvite;
    const initialFormData = {
        name: customer?.name,
        referenceId: room.referenceId,
        inviteType: inviteType,
        phone: customer?.phone,
    };

    const TimeAndExpertContent: FC<{ rightAligned?: boolean }> = ({ rightAligned }) => (
        <div>
            <AppText as="p" color="medium" data-testid="call-details-datetimestamp">
                {startDate?.toLocaleString(undefined, dateFormat)} at{' '}
                {startDate?.toLocaleTimeString(undefined, timeFormat)}
            </AppText>
            <AppText as="p" color="medium" data-testid="call-details-duration">
                {durationString}
            </AppText>
            {expert.name && (
                <ToUserWrapper rightAligned={rightAligned}>
                    <AppText as="p" data-testid="call-details-expert-name">
                        Call from{' '}
                        {expert.photoURL && (
                            <>
                                <InlineImg src={expert.photoURL} />{' '}
                            </>
                        )}
                        {expert.name}
                    </AppText>
                </ToUserWrapper>
            )}
        </div>
    );

    const hasAvailabilityDetails = !!room?.details.length;
    const roomOutcomeDetails = roomOutcomeReport?.roomOutcomeReport
        ? convertProtobufToRoomOutcome(roomOutcomeReport.roomOutcomeReport)
        : undefined;

    return (
        <>
            {customer?.name ? (
                <AppText as="h1" screenReaderOnly>
                    Call Details between ${customer.name} and ${expert.name}
                </AppText>
            ) : (
                <AppText as="h1" screenReaderOnly>
                    On site call from ${expert.name}
                </AppText>
            )}
            <TwoColumnLayout wideRight={!!roomOutcomeDetails}>
                <div>
                    <Box marginBottom={'16px'}>
                        <FromName as="h1" size="xxlarge" data-testid="call-details-customer-name">
                            {customer?.name}
                        </FromName>
                        {room?.referenceId && (
                            <ReferenceId as="h3" data-testid="call-details-reference-id">
                                Reference: {room.referenceId}
                            </ReferenceId>
                        )}
                    </Box>
                    {(hasAvailabilityDetails || !!roomOutcomeDetails) && <TimeAndExpertContent />}
                    {!isEmbedWorkflow && (
                        <Container>
                            <InviteToStreemButton initialFormData={initialFormData}>
                                {({ open, onClick }) => (
                                    <Button
                                        variant="tertiary"
                                        disabled={open}
                                        onClick={onClick}
                                        style={{
                                            transform: 'translateX(-16px)',
                                            paddingLeft: '12px',
                                        }}
                                    >
                                        {isPhoneInvite && (
                                            <>
                                                <ResizedAppIcon name="CallLogPhoneIcon" />
                                                <PhoneNumberText data-testid="call-details-invited-by">
                                                    {parsedPhone?.formatNational() || phone}
                                                </PhoneNumberText>
                                            </>
                                        )}
                                        {isLinkInvite && (
                                            <>
                                                <ResizedAppIcon name={'CallLogPhoneIcon'} />
                                                <PhoneNumberText data-testid="call-details-invited-by">
                                                    Re-send Streem invitation
                                                </PhoneNumberText>
                                            </>
                                        )}
                                    </Button>
                                )}
                            </InviteToStreemButton>
                        </Container>
                    )}
                    {isEmbedWorkflow && isPhoneInvite && (
                        <Container>
                            <>
                                <ResizedAppIcon name="CallLogPhoneIcon" />
                                <PhoneNumberText data-testid="embed-call-details-invited-by">
                                    {parsedPhone?.formatNational() || phone}
                                </PhoneNumberText>
                            </>
                        </Container>
                    )}
                </div>
                {!(hasAvailabilityDetails || !!roomOutcomeDetails) && (
                    <RightColumn>
                        <TimeAndExpertContent rightAligned />
                    </RightColumn>
                )}
                {(hasAvailabilityDetails || !!roomOutcomeDetails) && (
                    <Box>
                        <AppText as="h2" headingFontFamily size="mediumLarge">
                            Call Details
                        </AppText>
                        <DetailsContainer>
                            {hasAvailabilityDetails && (
                                <CallAvailabilityDetails details={room.details} />
                            )}
                            {!!roomOutcomeDetails && (
                                <RoomOutcomeDetails roomOutcomeReport={roomOutcomeDetails} />
                            )}
                        </DetailsContainer>
                    </Box>
                )}
            </TwoColumnLayout>
        </>
    );
};

const CallAvailabilityDetails: FC<{ details: streem.api.GroupReservation.IDetail[] }> = ({
    details,
}) => {
    return (
        <>
            {details
                .sort((a, b) => (a.label < b.label ? -1 : 1))
                .map(section => {
                    return (
                        <IndividualDetailContainer key={section.label}>
                            <AppText
                                as="h6"
                                headingFontFamily
                                semibold
                                data-testid={`availability-details-${section.label}`}
                            >
                                {section.label}
                            </AppText>
                            <AppText data-testid={`availability-details-${section.label}-value`}>
                                {section.value}
                            </AppText>
                        </IndividualDetailContainer>
                    );
                })}
        </>
    );
};
const RoomOutcomeDetails: FC<{ roomOutcomeReport: RoomOutcomeFormPayload }> = ({
    roomOutcomeReport,
}) => {
    const { disposition, diagnoses, customFields = undefined } = roomOutcomeReport;

    return (
        <>
            {diagnoses?.length > 0 && (
                <IndividualDetailContainer>
                    <AppText
                        as="h6"
                        headingFontFamily
                        semibold
                        data-testid="room-outcome-diagnosis"
                    >
                        Diagnosis
                    </AppText>
                    <AppText as="ol" style={{ listStyle: 'roman', marginLeft: '20px' }}>
                        {diagnoses.map((diagnosis, idx) => {
                            return (
                                <li
                                    data-testid={`room-outcome-diagosis-${idx + 1}-value`}
                                    key={diagnosis.label}
                                >
                                    {diagnosis.label}
                                </li>
                            );
                        })}
                    </AppText>
                </IndividualDetailContainer>
            )}
            <IndividualDetailContainer>
                <AppText as="h6" headingFontFamily semibold data-testid="room-outcome-disposition">
                    Disposition
                </AppText>
                <AppText data-testid="room-outcome-disposition-value">{disposition.label}</AppText>
            </IndividualDetailContainer>
            {customFields && customFields?.refund && (
                <>
                    <IndividualDetailContainer>
                        <AppText
                            as="h6"
                            headingFontFamily
                            semibold
                            data-testid="room-outcome-refund-request"
                        >
                            Refund request
                        </AppText>
                        <AppText data-testid="room-outcome-refund-request-value">
                            {customFields.refund.shouldRefund ? 'Yes' : 'No'}
                        </AppText>
                    </IndividualDetailContainer>
                    <IndividualDetailContainer>
                        <AppText
                            as="h6"
                            headingFontFamily
                            semibold
                            data-testid="room-outcome-refund-reason"
                        >
                            Reason for refund
                        </AppText>
                        <AppText data-testid="room-outcome-refund-reason-value">
                            {customFields.refund.reason ? customFields.refund.reason.label : 'None'}
                        </AppText>
                    </IndividualDetailContainer>
                </>
            )}
        </>
    );
};

const PhoneNumberText = styled(AppText)`
    color: inherit;
`;

const RightColumn = styled.div`
    align-self: center;
    justify-self: end;
    text-align: right;
    display: flex;
    @media (max-width: 500px) {
        justify-self: start;
        text-align: left;
    }
`;

const DetailsContainer = styled(Box)({
    display: 'grid',
    rowGap: '20px',
    gridTemplateColumns: 'repeat(4, 1fr)',
    columnGap: '32px',
    marginTop: '16px',
    maxHeight: '254px',
    overflowY: 'auto',
    '@media (max-width: 500px)': {
        gridTemplateColumns: '1fr 1fr',
        gridRowGap: '32px',
        maxHeight: 'unset',
    },
});

const IndividualDetailContainer = styled(Box)({
    overflowWrap: 'break-word',
});

const FromName = styled(Header)`
    margin-bottom: 4px;
    overflow-wrap: anywhere;
`;

const ResizedAppIcon = styled(AppIcon)`
    height: 24px;
    width: 24px;
    margin-right: 4px;
`;
const ToUserWrapper = styled(Row)<{ rightAligned: boolean }>(({ rightAligned }) => ({
    alignItems: 'center',
    justifyContent: rightAligned ? 'end' : 'start',
}));

const InlineImg = styled('img')`
    display: inline-block;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    margin: 0 4px;
    transform: translateY(2px);
`;

const TwoColumnLayout = styled.div<{ wideRight?: boolean }>`
    display: grid;
    grid-template-columns: 1fr ${props => (props.wideRight ? '2fr' : '1fr')};
    column-gap: 32px;
    @media (max-width: 500px) {
        grid-template-columns: 1fr;
        grid-row-gap: 32px;
    }
`;

const Container = styled.div`
    display: grid;
    grid-column-gap: 4px;
    grid-row-gap: 2px;
    align-content: center;
    align-items: center;
    grid-template-columns: min-content max-content;
    grid-template-rows: auto;
`;

const ReferenceId = styled(Header)(({ theme }) => ({
    display: 'inline-block',
    background: theme.colors.grey05,
    borderRadius: '4px',
    padding: '4px 8px',
    color: 'rgba(44, 44, 44, 0.73)',
    fontSize: '1rem',
    margin: '0 16px 0 0',
}));
