import { useMediaBreakpoint } from '@streem/sdk-react';
import { useDetailSession } from '../../../../hooks/detail_session_hooks';
import {
    MediaGallerySelected,
    MediaGallerySelectedProps,
} from '../../../media_gallery/media_gallery_selected';

type RequiredMediaGalleryProps = Required<
    Pick<
        MediaGallerySelectedProps,
        | 'streemshots'
        | 'selectedStreemshotIds'
        | 'streemshotsImageDataRef'
        | 'errorMessage'
        | 'setErrorMessage'
        | 'logger'
    >
>;
type StreemshotsSelectedPopupProps = RequiredMediaGalleryProps & {
    selectAllStreemshots: () => void;
    clearSelectedStreemshots: () => void;
    // Add this separately to allow it to be optional
    readOnly?: boolean;
};
export const StreemshotsSelectedPopup = ({
    streemshots,
    selectedStreemshotIds,
    streemshotsImageDataRef,
    readOnly,
    clearSelectedStreemshots,
    selectAllStreemshots,
    errorMessage,
    setErrorMessage,
    logger,
}: StreemshotsSelectedPopupProps): JSX.Element => {
    const detailSession = useDetailSession();
    const isMobile = useMediaBreakpoint().isMobile;
    const selectionSize = selectedStreemshotIds.size;
    const selectionVisible = selectionSize > 0 || isMobile;

    return (
        <MediaGallerySelected
            detailSession={detailSession}
            streemshots={streemshots}
            selectedStreemshotIds={selectedStreemshotIds}
            streemshotsImageDataRef={streemshotsImageDataRef}
            selectionSize={selectionSize}
            isMobile={isMobile}
            selectionVisible={selectionVisible}
            readOnly={readOnly}
            onUnselect={clearSelectedStreemshots}
            onSelectAll={selectAllStreemshots}
            clearSelection={clearSelectedStreemshots}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            logger={logger}
        />
    );
};
