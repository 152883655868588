import { AppText, IconButton, Markdown, styled, Theme } from '@streem/ui-react';
import { NotesHeader } from './notes_header';
import { useNotes } from '../../../../../hooks/use_notes';

interface ViewModeNotesProps {
    text?: string;
    readOnly: boolean;
}
export const ViewModeNotes = ({ text, readOnly }: ViewModeNotesProps): JSX.Element => {
    const { errorMessage, onClickEdit, saveMessageVisibleYn } = useNotes();
    return (
        <>
            <Header
                onEdit={onClickEdit}
                readOnly={readOnly}
                saveMessageVisibleYn={saveMessageVisibleYn}
                errorMessage={errorMessage}
            />
            <NotesWrapper>
                <Markdown text={text} />
            </NotesWrapper>
        </>
    );
};

interface EditIconProps {
    onEdit: () => void;
}
type HeaderProps = EditIconProps & {
    readOnly: boolean;
    saveMessageVisibleYn: boolean;
    errorMessage?: string;
};
const Header = ({
    onEdit,
    readOnly,
    saveMessageVisibleYn,
    errorMessage,
}: HeaderProps): JSX.Element => {
    let component: JSX.Element | undefined = undefined;
    if (saveMessageVisibleYn && !errorMessage) {
        component = <Text text="Saved!" color="medium" testId="saved-message" />;
    } else if (!readOnly) {
        component = <EditIcon onEdit={onEdit} />;
    }

    const children = errorMessage ? (
        <Text text={errorMessage} color="red50" testId="error-message" />
    ) : undefined;

    return <NotesHeader component={component}>{children}</NotesHeader>;
};

const EditIcon = ({ onEdit }: EditIconProps): JSX.Element => {
    return (
        <EditIconButton
            iconName="EditIcon"
            name="editNotes"
            label="Edit Notes"
            onClick={onEdit}
            data-testid="edit-notes-button"
            style={{ height: '1.5rem' }}
            iconSize="1.5rem"
        />
    );
};

interface TextProps {
    text: string;
    color: keyof typeof Theme['text']['color'];
    testId: string;
}
export const Text = ({ text, color, testId }: TextProps): JSX.Element => {
    return (
        <AppText color={color} data-testid={testId} style={{ paddingRight: '.25rem' }}>
            {text}
        </AppText>
    );
};

const EditIconButton = styled(IconButton)({
    position: 'relative',
    top: '0.125rem', // Line button up with text
});

export const NotesWrapper = styled.div({
    backgroundColor: Theme.colors.grey01A50,
    borderRadius: '4px',
    minHeight: '16rem',
    padding: '.5rem 1rem',
    ':hover': {
        backgroundColor: Theme.colors.hoverBlue,
    },
});
