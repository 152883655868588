import { AppText, styled, Theme } from '@streem/ui-react';
import { Header } from '../../shared_styled_components';
import { PropsWithChildren } from 'react';

interface NotesHeaderProps {
    component?: JSX.Element;
}
export const NotesHeader = ({
    component,
    children,
}: PropsWithChildren<NotesHeaderProps>): JSX.Element => {
    return (
        <NotesHeaderWrapper>
            <HeaderText headingFontFamily data-testid="call-notes-heading">
                Call Notes
            </HeaderText>
            {children}
            {component}
        </NotesHeaderWrapper>
    );
};

const NotesHeaderWrapper = styled(Header)({
    display: 'flex',
    justifyContent: 'space-between',
});

// 1.125rem does not exist in `fontSizes` in Theme, so cannot use the size
//    property on AppText
const HeaderText = styled(AppText)({
    fontSize: Theme.text.size.notQuiteAllTheWayMediumLarge,
});
