import { Column, styled } from '@streem/ui-react';

import { Items } from './items';
import { MemberInformationAndDiagnosis } from './member_information_and_diagnosis';
import { useCallDetailHeaderInfo } from '../../../../hooks/use_call_detail_header_info';
import { tabWrapperSharedProps } from '../shared_styled_components';

export const CallDetailDetailTab = ({ hiddenYn }: { hiddenYn: boolean }): JSX.Element => {
    const { callDetail, groupName } = useCallDetailHeaderInfo();
    return (
        <Wrapper hiddenYn={hiddenYn}>
            <Items groupName={groupName} items={callDetail?.items ?? []} />
            <MemberInformationAndDiagnosis />
        </Wrapper>
    );
};

const Wrapper = styled(Column)<{ hiddenYn: boolean }>(({ hiddenYn }) => ({
    rowGap: '4rem',
    ...tabWrapperSharedProps({ hiddenYn }),
}));
