import { computed, observable, flow } from 'mobx';
import { APICallState } from './api_call_store';
import { APIError, APITypes } from '@streem/api';
import { Logger } from '@streem/logger';
import appLogger from '../util/logging/app_logger';

export enum ListStoreState {
    loading,
    complete,
    error,
    refreshing,
    loadingMore,
}

export interface ListResponse {
    nextPageToken?: string;
    totalSize?: number;
}

export interface ListRequest {
    companySid?: string;
    pageToken?: string;
    pageSize?: number;
}

export interface ListStorePaginatedOpts<Rsp extends ListResponse, Result> {
    filter: (r: Rsp) => Result[];
    fnName: string; // used for logging
}

export class ListStorePaginated<Req extends ListRequest, Rsp extends ListResponse, Result> {
    protected log: Logger;

    @observable
    protected _baseRequest?: Req;

    @observable
    protected _scrollPosition = 0;

    protected nextPageTokens: string[] = [];

    @observable
    protected internalHasNextPage = false;

    @observable
    protected internalResults: Result[] = [];

    @observable
    public state: APICallState = APICallState.loading;

    @observable
    public lastError?: APIError;

    @observable
    public totalSize?: number = 0;

    constructor(
        protected readonly apiCallRaw: (r: Req) => Promise<APITypes.ApiResponse<Rsp>>,
        protected readonly opts: ListStorePaginatedOpts<Rsp, Result>,
    ) {
        this.log = appLogger.extend(`ListStorePaginated:${opts.fnName}`);
    }

    @computed
    public get scrollPosition() {
        return this._scrollPosition;
    }

    @computed
    public get baseRequest() {
        return this._baseRequest;
    }

    @computed
    public get loading() {
        return this.state === (this.internalResults.length === 0 && APICallState.loading);
    }

    @computed
    public get loadingMore() {
        return this.state === APICallState.loadingMore;
    }

    @computed
    public get complete() {
        return this.state === APICallState.complete;
    }

    @computed
    public get results() {
        return this.internalResults;
    }

    @computed
    public get hasNextPageToken() {
        return this.internalHasNextPage;
    }

    @computed
    public get totalSizeOfResults() {
        return this.totalSize;
    }

    public fetchFirstPage = flow(this._fetchFirstPage);
    public fetchNextPage = flow(this._fetchNextPage);
    public refresh = flow(this._refresh);
    public refreshable(): boolean {
        return this._baseRequest !== undefined;
    }

    public setScrollPosition(scrollPosition: number) {
        this._scrollPosition = scrollPosition;
    }

    protected async apiCall(request: Req): Promise<Rsp> {
        const response = await this.apiCallRaw(request);
        return await response.value();
    }

    protected *_fetchFirstPage(
        request: Exclude<Req, 'pageToken'>,
        options?: { clearCache: boolean },
    ) {
        const { clearCache = true } = options || {};
        // We want to clear the cache if there is a filter applied and we are about to fetch a new initial page result
        if (!clearCache) {
            // Limit cached results to match requested page size
            this.internalResults = this.internalResults.slice(0, request.pageSize);
        } else {
            this.internalResults = [];
        }
        this._scrollPosition = 0;
        this.state = APICallState.loading;
        this.lastError = undefined;
        this.nextPageTokens = [];
        this.internalHasNextPage = false;
        try {
            const response: Rsp = yield this.apiCall(request);
            this._baseRequest = request;

            if (response.nextPageToken) {
                this.internalHasNextPage = true;
                this.nextPageTokens.push(response.nextPageToken);
            }

            this.internalResults = this.opts.filter(response);
            this.totalSize = response.totalSize ?? this.internalResults.length;
            this.state = APICallState.complete;
        } catch (e: unknown) {
            this.log.error(e);
            if (e instanceof APIError) {
                this.lastError = e;
                this.state = APICallState.error;
            }
        }
    }

    private *_fetchNextPage() {
        if (!this._baseRequest) {
            this.log.warn('Unable to request next page without first page');
            return;
        }

        this.state = APICallState.loadingMore;
        this.lastError = undefined;

        try {
            const response: Rsp = yield this.apiCall({
                ...this._baseRequest,
                pageToken: this.nextPageTokens[this.nextPageTokens.length - 1],
            });

            if (response.nextPageToken) {
                this.internalHasNextPage = true;
                this.nextPageTokens.push(response.nextPageToken);
            } else {
                this.internalHasNextPage = false;
            }

            this.internalResults.push(...this.opts.filter(response));
            this.totalSize = response.totalSize ?? this.internalResults.length;
            this.state = APICallState.complete;
        } catch (e: unknown) {
            this.log.error(e);
            if (e instanceof APIError) {
                this.lastError = e;
                this.state = APICallState.error;
            }
        }
    }

    private *_refresh() {
        if (this._baseRequest === undefined) {
            this.log.warn('Unable to refresh API call without lastArgs.');
            return;
        }
        this.state = APICallState.refreshing;
        this.lastError = undefined;

        try {
            // Hush Typescript
            const copyBaseRequest = Object.assign({}, this._baseRequest);
            // Only get previous page tokens
            const tokens = this.nextPageTokens.slice(0, this.nextPageTokens.length - 1);
            // Re-fetch all of the previous pages
            const responses: Rsp[] = yield Promise.all([
                this.apiCall(this._baseRequest),
                ...tokens.map(pageToken =>
                    this.apiCall({
                        ...copyBaseRequest,
                        pageToken,
                    }),
                ),
            ]);
            this.internalResults = responses.map(response => this.opts.filter(response)).flat();
            this.state = APICallState.complete;
        } catch (e) {
            this.log.error(e);
            if (e instanceof APIError) {
                this.lastError = e;
                this.state = APICallState.error;
            }
        }
    }
}
