import { css, Global } from '@streem/ui-react';
import { FC } from 'react';

// hide scrollbar on body.  This will only be applied for the call details route to minimize potential impact on other routes.
//    I think it would be safe to apply to all routes, but I'm not sure if there are any edge cases where this would cause issues.
const styles = css`
    body {
        overflow-y: hidden;
    }
`;

export const BodyStyles: FC = () => <Global styles={styles} />;
