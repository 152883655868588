import {
    APIError,
    StreemAPI,
    StreemApiAcquireEmbedReportsSessionResponse,
    StreemApiRefreshEmbedReportsSessionResponse,
} from '@streem/api';
import { getCompanyCodeFromUrl, invariant } from '@streem/toolbox';
import { observable, action, computed, runInAction } from 'mobx';
import { pick } from 'lodash';
import { convertServerRoleToSwagaRole } from '../util/roles';
import appLogger from '../util/logging/app_logger';
import { contextValues } from '@streem/logger';
import Analytics, { recordIdentifyAttempted, recordLoggedOut } from '@streem/analytics';
import {
    addUserToDatadogRumSession,
    addBrowserNotificationPermissionToDatadogRumSession,
} from '../util/datadog';
import { Role, SESSION_SDK_TOKEN, SESSION_SWAGA_POST_LOGOUT_SUCCESS } from '../types/project.types';
import StreemAuth, { AnyUser, StreemAuthError, ExpertUser, isExpertUser } from '@streem/auth';
import { LookerEmbedCookielessSessionData } from '@looker/embed-sdk';

const LOOKER_DASHBOARD_TOKEN = 'LOOKER_DASHBOARD_TOKEN';
let localLookerSessionToken: string | null = null;

export class AuthStore {
    private unsubscriber?: () => void;

    @observable
    public acceptedLatestTCs = false;

    @observable
    public associatedCompanyCount: number;

    @observable
    public errorFetchingCompanies?: StreemAuthError;

    // To clear up confusion between authStore and userStore, we only publicly
    // expose properties of user which the authStore should be responsible for.
    @observable
    private user: ExpertUser | undefined;

    // The initialized property indicates whether we've received the initial
    // response from @streem/auth which indicates our initial auth state.
    @observable
    public initialized = false;

    // For async OAuth flows it's possible for errors to be returned from the
    // OAuth provider, in which case they enter via the setAuthUser callback.
    @observable
    public error?: StreemAuthError;

    @computed
    public get isUserSignedIn(): boolean {
        return this.user !== undefined;
    }

    @computed
    public get role(): Role {
        if (!this.user) {
            return 'ANONYMOUS';
        }

        const role = convertServerRoleToSwagaRole(this.user.scope);
        appLogger.setContextValue(contextValues.ROLE, role);
        return role;
    }

    @computed
    public get userId() {
        return this.user?.id;
    }

    @computed
    public get companyCode() {
        return this.user?.companyCode;
    }

    @computed
    public get isSuperAdmin() {
        return this.role === 'SUPER_ADMIN';
    }

    @computed
    public get isAdmin() {
        return this.role === 'COMPANY_ADMIN';
    }

    @computed
    public get isAgent() {
        return this.role === 'AGENT';
    }

    @computed
    public get isAnonymous() {
        return this.role === 'ANONYMOUS';
    }

    public async acceptLatestTCs() {
        invariant(this.userId, 'User must be logged in before accepting terms and conditions.');
        try {
            await StreemAPI.users.saveUserTermsStatus(this.userId, {
                acceptedLatest: true,
            });
            appLogger.info('User accepted terms and conditions: ', this.userId);
            runInAction(() => (this.acceptedLatestTCs = true));
        } catch (e) {
            appLogger.error('Failed to accept terms and conditions: ', e);
            throw e;
        }
    }

    public async loginWithSdkToken(sdkToken: string, companyCode: string) {
        try {
            const user = await StreemAuth.loginWithSDKToken(sdkToken, companyCode);
            appLogger.info(`Successful login, user id: ${user.id}`);
            recordIdentifyAttempted(true);
        } catch (err: unknown) {
            appLogger.error(
                `Unable to set auth user in company: '${companyCode}' with provided sdk token: `,
                { error: err },
            );
            recordIdentifyAttempted(false);

            // TODO: instead of doing the redirect below, call `setAuthUser(undefined, err)` so that `authStore.error`
            //  gets set. Then the code in login_page.tsx that observes `authStore.error` can react accordingly and do
            //  the routing from React code. This would also match the flow implemented in CV2's app_store.ts.
            // if (err instanceof Error) {
            //    await this.setAuthUser(undefined, err);
            // }

            // token is invalid or expired, so remove it from session storage and redirect to session expired page.
            sessionStorage.removeItem(SESSION_SDK_TOKEN);
            window.location.href = '/session-expired';
        }
    }

    public async loginWithOauthRedirect(companyCode: string) {
        await StreemAuth.loginWithOauthRedirect(companyCode);
    }

    public async logout() {
        // we need to record log out event and flush all queued events before logging out, since the access token
        // will be invalid after logging out.
        recordLoggedOut();
        await Analytics.flushQueuedEventsAndEndTimer();

        if (this.user) {
            try {
                appLogger.info('Logging out the user via API');
                await StreemAPI.auth.authLogout();
            } catch (e) {
                appLogger.error('Error logging out the user via API', e);
            }
        }

        localStorage.setItem(SESSION_SWAGA_POST_LOGOUT_SUCCESS, 'true');

        // ST-2599: Always explicitly logout from the current company code in context
        const companyCode = getCompanyCodeFromUrl();
        await StreemAuth.logout(companyCode);
    }

    private async fetchAssociatedCompanyCount(userId: string): Promise<number> {
        try {
            return (await StreemAPI.users.getLoginIdentityCompanies(userId, true)).companies.length;
        } catch (e) {
            appLogger.error('Error fetching associated company count', e);
            this.errorFetchingCompanies = new Error('Unable to fetch associated companies');
        }
    }

    /**
     * disconnect invokes the callback on StreemAuth's onAuthStateChanged
     */
    public disconnect() {
        if (this.unsubscriber) {
            this.unsubscriber();
        }
    }

    /**
     * connect registers a callback to StreemAuth's onAuthStateChanged
     */
    public connect() {
        this.unsubscriber = StreemAuth.onAuthStateChanged(this.setAuthUser);
    }

    @action.bound
    private async setAuthUser(user: AnyUser | undefined, error?: StreemAuthError) {
        // Start by resetting local state.
        this.user = undefined;
        this.error = error;
        this.associatedCompanyCount = null;
        this.acceptedLatestTCs = false;

        // Skip further code paths if no user is defined.
        if (!user) {
            this.initialized = true;
            appLogger.info('Initialized AuthStore without a user');
            return;
        }

        // Then handle the change in auth state..
        try {
            invariant(isExpertUser(user), 'Expected type of ExpertUser from StreemAuth');
            const latestTCs = await StreemAPI.users.getUserTermsStatus(user.id);
            const companyCount = await this.fetchAssociatedCompanyCount(user.id);
            runInAction(() => {
                this.acceptedLatestTCs = Boolean(latestTCs.status.acceptedLatest);
                this.associatedCompanyCount = companyCount;
                this.user = user;
                this.initialized = true;
                appLogger.info(`Initialized AuthStore with user=${user.id}`);
                addUserToDatadogRumSession(user);
                addBrowserNotificationPermissionToDatadogRumSession();
            });
        } catch (error) {
            appLogger.error('Error in AuthStore.setAuthUser', error);
            runInAction(() => {
                this.initialized = true;
                this.error = new Error('Unable to log you in.');
            });
        }
    }

    private storeEmbedAccessTokens(
        lookerSessionToken:
            | StreemApiAcquireEmbedReportsSessionResponse
            | StreemApiRefreshEmbedReportsSessionResponse,
    ) {
        const token = JSON.stringify(pick(lookerSessionToken, ['apiToken', 'navigationToken']));
        sessionStorage.setItem(LOOKER_DASHBOARD_TOKEN, token);

        localLookerSessionToken = token;
    }

    private retrieveEmbedAccessTokens(): { apiToken: string; navigationToken: string } {
        let token = sessionStorage.getItem(LOOKER_DASHBOARD_TOKEN);

        if (!token) {
            if (localLookerSessionToken) {
                appLogger.warn(
                    'Unable to find Looker Dashboard token in sessionStorage, but did find in local variable... using local variable',
                );
                token = localLookerSessionToken;
            } else {
                throw new Error(
                    'Failed to retrieve Looker tokens from SessionStorage or in local variable',
                );
            }
        }

        try {
            return JSON.parse(token);
        } catch (error) {
            throw new Error('Failed to parse Looker tokens from SessionStorage', error);
        }
    }

    public async getEmbedReportsSessionTokens(): Promise<LookerEmbedCookielessSessionData> {
        try {
            const companyCode = getCompanyCodeFromUrl();
            const company = await StreemAPI.companies.getCompany(companyCode);

            const sessionTokens = await StreemAPI.companies.acquireEmbedReportsSession(
                company.company.sid,
            );
            this.storeEmbedAccessTokens(sessionTokens);

            return {
                authentication_token: sessionTokens.authenticationToken,
                authentication_token_ttl: sessionTokens.authenticationTokenTtl.seconds,
                navigation_token: sessionTokens.navigationToken,
                navigation_token_ttl: sessionTokens.navigationTokenTtl.seconds,
                api_token: sessionTokens.apiToken,
                api_token_ttl: sessionTokens.apiTokenTtl.seconds,
                session_reference_token_ttl: sessionTokens.sessionReferenceTokenTtl.seconds,
            };
        } catch (error) {
            appLogger.error('Error fetching session tokens for Embedded Reports', error);
            throw new Error(`Error fetching session tokens for Embedded Reports: ${error}`);
        }
    }

    public async refreshEmbedReportsSessionTokens(): Promise<LookerEmbedCookielessSessionData> {
        try {
            const companyCode = getCompanyCodeFromUrl();

            const { apiToken, navigationToken } = this.retrieveEmbedAccessTokens();

            const refreshTokens = await StreemAPI.companies.refreshEmbedReportsSession(
                companyCode,
                {
                    apiToken,
                    navigationToken,
                },
            );

            this.storeEmbedAccessTokens(refreshTokens);

            return {
                api_token: refreshTokens.apiToken,
                api_token_ttl: refreshTokens.apiTokenTtl.seconds,
                navigation_token: refreshTokens.navigationToken,
                navigation_token_ttl: refreshTokens.navigationTokenTtl.seconds,
                session_reference_token_ttl: refreshTokens.sessionReferenceTokenTtl.seconds,
            };
        } catch (error) {
            if (error instanceof APIError && error.response.status === 400) {
                try {
                    const errorResponse = await error.response.json();

                    switch (errorResponse.error.code) {
                        // https://cloud.google.com/looker/docs/cookieless-embed#generate_tokens
                        case 'API.EmbeddedReports.SessionTokenNotFound': {
                            appLogger.error('Looker error:', errorResponse.error.message);
                            return { session_reference_token_ttl: 0 };
                        }
                        case 'API.EmbeddedReports.ExpiredSessionToken': {
                            appLogger.error('Looker error:', errorResponse.error.message);
                            return { session_reference_token_ttl: 0 };
                        }
                        default:
                            throw new Error(
                                `Unexpected Looker error while refreshing session tokens: ${JSON.stringify(
                                    errorResponse,
                                )}`,
                            );
                    }
                } catch (error) {
                    appLogger.error('Error refreshing Looker tokens', error);
                }
            }
            appLogger.error('Error fetching refresh tokens for Embedded Reports', error);
            throw new Error(`Error fetching refresh tokens for Embedded Reports: ${error}`);
        }
    }
}
