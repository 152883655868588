import { NotesProvider, useInitializeNotes } from '../../../../../hooks/use_notes';
import { ViewModeNotes } from './view_mode_notes';
import { EditModeNotes } from './edit_mode_notes';

export const CallNotes = ({ readOnly }: { readOnly?: boolean }): JSX.Element => {
    const notesData = useInitializeNotes();
    const { isEditingYn, noteText } = notesData;

    return (
        <NotesProvider value={notesData}>
            <CallNotesView isEditingYn={isEditingYn} text={noteText ?? ''} readOnly={readOnly} />
        </NotesProvider>
    );
};

interface CallNotesViewProps {
    isEditingYn: boolean;
    text: string;
    readOnly?: boolean;
}
const CallNotesView = ({ isEditingYn, text, readOnly }: CallNotesViewProps): JSX.Element => {
    // Show the editable notes if the user is editing or if there is no text
    return isEditingYn || !text ? (
        <EditModeNotes
            placeholderText="Add notes here"
            isEditingDisabledYn={readOnly}
            testId="call-notes-editable"
        />
    ) : (
        <ViewModeNotes text={text} readOnly={readOnly} />
    );
};
