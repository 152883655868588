import { useRef } from 'react';
import { Grid, styled } from '@streem/ui-react';
import { observer } from 'mobx-react';
import { isRecordingReady } from '../../../../util/artifacts';
import { OnClickTimeStamp, StreemshotThumbnail } from '../../../media_gallery/thumbnail';
import { Header, EmptySection, PhotoSectionWrapper } from '../shared_styled_components';
import { usePhotos } from '../../../../hooks/use_photos';
import { StreemshotImageData } from '../../../../util/media_gallery_selected_utils';
import appLogger from '../../../../util/logging/app_logger';
import { StreemshotsSelectedPopup } from './streemshots_selected_popup';
import { Streemshot } from '@streem/sdk-core';

export const Photos = observer((): JSX.Element => {
    const {
        streemshots,
        recordings,
        onClickTimeStamp,
        selectedStreemshotIds,
        isMobile,
        handleArtifactSelect,
        clearSelectedStreemshots,
        selectAllStreemshots,
        isVideoReadyYn,
        readOnly,
        errorMessage,
        setErrorMessage,
    } = usePhotos();
    const streemshotsImageDataRef = useRef<StreemshotImageData>({});

    const selectionSize = selectedStreemshotIds.size;
    const selectionVisible = selectionSize > 0 || isMobile;
    const someRecordingsReady = isRecordingReady(recordings);
    const canClickTimeStamp = someRecordingsReady && recordings.length > 0 && isVideoReadyYn;

    return (
        <PhotoSectionWrapper>
            <Header>Photos</Header>
            <Streemshots
                streemshots={streemshots}
                canClickTimeStamp={canClickTimeStamp}
                streemshotsImageDataRef={streemshotsImageDataRef}
                selectedStreemshotIds={selectedStreemshotIds}
                isSelectVisible={selectionVisible}
                handleArtifactSelect={handleArtifactSelect}
                onClickTimeStamp={onClickTimeStamp}
            />
            <StreemshotsSelectedPopup
                streemshots={streemshots}
                selectedStreemshotIds={selectedStreemshotIds}
                streemshotsImageDataRef={streemshotsImageDataRef}
                readOnly={readOnly}
                clearSelectedStreemshots={clearSelectedStreemshots}
                selectAllStreemshots={selectAllStreemshots}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                logger={appLogger}
            />
        </PhotoSectionWrapper>
    );
});

interface StreemshotsProps {
    streemshots: Streemshot[];
    canClickTimeStamp: boolean;
    streemshotsImageDataRef: React.MutableRefObject<StreemshotImageData>;
    selectedStreemshotIds: Set<string>;
    isSelectVisible: boolean;
    handleArtifactSelect: (streemshotId: string) => void;
    onClickTimeStamp: OnClickTimeStamp;
}
export const Streemshots = ({
    streemshots,
    canClickTimeStamp,
    streemshotsImageDataRef,
    selectedStreemshotIds,
    isSelectVisible,
    handleArtifactSelect,
    onClickTimeStamp,
}: StreemshotsProps): JSX.Element => {
    if (streemshots.length === 0) {
        return <EmptySection>No photos available</EmptySection>;
    }

    return (
        <StreemshotWrapper>
            {streemshots.map(streemshot => {
                return (
                    <StreemshotThumbnail
                        canClickTimeStamp={canClickTimeStamp}
                        canSelect={true}
                        imageDataRef={streemshotsImageDataRef}
                        isSelected={
                            selectedStreemshotIds.has(streemshot.id) ||
                            selectedStreemshotIds.has(streemshot.streemshotId)
                        }
                        isSelectVisible={isSelectVisible}
                        key={streemshot.id}
                        onSelect={() =>
                            handleArtifactSelect(streemshot?.streemshotId || streemshot.id)
                        }
                        streemshot={streemshot}
                        onClickTimeStamp={onClickTimeStamp}
                    />
                );
            })}
        </StreemshotWrapper>
    );
};

const StreemshotWrapper = styled(Grid)({
    gridTemplateColumns: 'repeat(3, minmax(15.625rem, 1fr))',
    // There is less horizontal space in the new layout, so adjust the
    //     image size to preserve the aspect ratio and allow for 3 images
    //     per row
    gridAutoRows: '21.25rem',
    gridAutoFlow: 'dense',
    gap: '2rem',
});
