import { Row, styled, Theme } from '@streem/ui-react';
import { CSSProperties } from 'react';

export const PhotoSectionWrapper = styled.section({
    border: `1px solid ${Theme.colors.grey10}`,
    padding: '1rem',
});

export const SummarySectionWrapper = styled.section({
    border: `1px solid ${Theme.colors.grey10}`,
    padding: '1.5rem',
});

export const Header = styled.h5({
    fontSize: Theme.text.size.notQuiteAllTheWayMediumLarge,
    lineHeight: Theme.text.lineHeight.notQuiteAllTheWayMediumLarge,
    fontWeight: 500,
    fontFamily: Theme.text.headingFontFamily,
    marginBottom: '1.5rem',
});

// CSSProperties is missing the hidden property, so we need to extend it.  If we update the version
//      of typescript in the future, this might not be needed.
type SharedProps = CSSProperties & { hidden?: string };

// The tabs will all be rendered, but only the active tab will be visible.  The other 2 will be hidden,
//      have a height of 0, an opacity of 0, and will be positioned off screen to the left.  The left
//      positioning is to prevent the hidden tabs from being focusable.
export const tabWrapperSharedProps = ({ hiddenYn }: { hiddenYn: boolean }): SharedProps => ({
    opacity: hiddenYn ? 0 : 1,
    hidden: hiddenYn ? 'hidden' : undefined,
    height: hiddenYn ? 0 : undefined,
    transition: 'opacity 0.25s ease-in 0.1s',
    position: 'relative',
    left: hiddenYn ? '-100%' : undefined,
});

export const EmptySection = styled(Row)(({ theme }) => ({
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    backgroundColor: theme.colors.grey01A50,
    padding: '2rem 0',
}));
