import { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { MessagePageWithOptionalButton } from './errors/message_page_with_optional_button';
import { EmbedSessionKeys } from '../types/project.types';

export const ServerErrorRoute: FC<RouteComponentProps> = ({ history }) => {
    const isEmbed = sessionStorage.getItem(EmbedSessionKeys.IS_EMBED);

    const serverErrorHeader = 'Well, that was unexpected!';
    const serverErrorMessage = () => (
        <p role="contentinfo">
            If the page doesn't load, wait a few minutes and try again. If the problem persists,
            contact support.
        </p>
    );

    return (
        <>
            <MessagePageWithOptionalButton
                dataTestId="server-error-page"
                header={serverErrorHeader}
                message={serverErrorMessage()}
                hasButton={!isEmbed}
                clickHandler={() => history.push('/')}
            />
        </>
    );
};
