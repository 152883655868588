import { APIError, APITypes } from '@streem/api';
import {
    ListRequest,
    ListResponse,
    ListStorePaginated,
    ListStorePaginatedOpts,
} from './list_store_paginated';
import { APICallState } from './api_call_store';
import appLogger from '../util/logging/app_logger';
import { flow } from 'mobx';
import debounce from 'lodash.debounce';
import { CALL_LOG_DEBOUNCE_TIME } from '../util/constants';

export class CallLogPaginatedListStore<
    Req extends ListRequest,
    Rsp extends ListResponse,
    Result,
> extends ListStorePaginated<Req, Rsp, Result> {
    constructor(
        apiCallRaw: (r: Req) => Promise<APITypes.ApiResponse<Rsp>>,
        opts: ListStorePaginatedOpts<Rsp, Result>,
    ) {
        super(apiCallRaw, opts);
        this.log = appLogger.extend(`CallLogPaginatedListStore:${opts.fnName}`);
    }

    public fetchFirstPage = flow(this._fetchFirstPage);

    // override to debounce the API call.  Only care about debouncing fetchFirstPage because it is called in
    //    response to user input in the search field
    protected *_fetchFirstPage(
        request: Exclude<Req, 'pageToken'>,
        options?: { clearCache: boolean },
    ) {
        const { clearCache = true } = options || {};
        // We want to clear the cache if there is a filter applied and we are about to fetch a new initial page result
        if (!clearCache) {
            // Limit cached results to match requested page size
            this.internalResults = this.internalResults.slice(0, request.pageSize);
        } else {
            this.internalResults = [];
        }
        this._scrollPosition = 0;
        this.state = APICallState.loading;
        this.lastError = undefined;
        this.nextPageTokens = [];
        this.internalHasNextPage = false;
        yield this.callDebouncedApi(request);
    }

    private callDebouncedApi = debounce(
        async (request: Req): Promise<Rsp> => {
            try {
                const response: Rsp = await this.apiCall(request);
                this._baseRequest = request;

                if (response.nextPageToken) {
                    this.internalHasNextPage = true;
                    this.nextPageTokens.push(response.nextPageToken);
                }

                this.internalResults = this.opts.filter(response);
                this.totalSize = response.totalSize ?? this.internalResults.length;
                this.state = APICallState.complete;
                return response;
            } catch (e: unknown) {
                this.log.error(e);
                if (e instanceof APIError) {
                    this.lastError = e;
                    this.state = APICallState.error;
                }
            }
        },
        // Ideally this would be a configuration option for this class, but the value
        //    needs to be set before the constructor is run, so it is a constant for now
        CALL_LOG_DEBOUNCE_TIME,
    );
}
